import React from "react";
import styled from "styled-components";

const SectionContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d8d9d9;
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.direction === "LTR" ? "row" : "row-reverse"};

  @media (max-width: 600px) {
    flex-direction: column;
  }
`; 

const ImageContainer = styled.div`
  width: 50%;
  height: 100vh;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 70%;
  }
  @media (max-width: 1200px) {
    width: 50%;

    img {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0px;

    img {
      width: 80%;
    }
  }
`;

const TextContainer = styled.div`
  width: 40%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-weight: 500;
    font-size: 4rem;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
  }

  h2 {
    font-weight: 300;
    font-size: 3rem;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
  }

  wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2rem;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
    h1 {
      font-size: 2.5rem;
      text-align: center;
    }

    h2 {
      font-size: 1.3rem;
      text-align: center;
    }
  }
`;

const Description = styled.p`
  font-weight: 200;
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

function Section({ title, tagline, description, image, direction = "LTR" }) {
  return (
    <SectionContainer direction={direction}>
      <ImageContainer>
        <img src={image} alt={title} />
      </ImageContainer>
      <TextContainer>
        <div className="wrapper">
          <h1>{title}</h1>
          <h2>{tagline}</h2>
          <Description>{description}</Description>
        </div>
      </TextContainer>
    </SectionContainer>
  );
}

export default Section;
