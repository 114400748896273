import React from "react";
import { useParams } from "react-router-dom";
import HorizontalPlayer from "./HorizontalPlayer";
import VerticalPlayer from "./VerticalPlayer";

function Plays() {
  const { orientation, token } = useParams();

  if (orientation === "horizontal") {
    return <HorizontalPlayer token={token} />;
  } else {
    return <VerticalPlayer token={token} />;
  }
}

export default Plays;
