import React from "react";
import logo from "../assets/white_logo.png";
import wallpaper from "../assets/wallpaper.png";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url(${wallpaper});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    background-image: none;
    background-color: #212226;
    align-items: center;
    justify-content: center;

  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 600px) {
    width:80%;
    /* height: 80%; */
  }
`;

const Title = styled.h1`
  color: #d8d9d9;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const SubTitle = styled.h2`
  color: #d8d9d9;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  mix-blend-mode: difference;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Description = styled.p`
  color: #d8d9d9;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  text-align: center;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  mix-blend-mode: difference;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
const JoinTitle = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    color: #d8d9d9;
    font-family: "Poppins", sans-serif;
    font-size: 2.5rem;
    font-weight: 100;
  }
`;
const ApplyButton = styled.button`
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 25px 0px 25px 25px;
  color: #212226;
  font-size: 1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #d8d9d9;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  padding: 15px 25px;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d8d9d9;
    border-radius: 25px 0px 25px 25px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #212226;
    transition: all 0.3s;
    border-radius: 25px 0px 25px 25px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    background-color: transparent;
    border: 2px solid #d8d9d9;
    &:before {
      width: 100%;
    }
  }
  @media (max-width: 1200px) {
    margin-top: 10px;
    border-radius: 20px 0px 20px 20px;
    padding: 10px 20px;
    font-size: 1.3rem;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;


const Career = () => {
  const onApplyClick = () => {
    window.location.href = "https://forms.gle/16VGzqcK5mjiYGKF7";
  };

  return (
    <>
      <Navbar logo={logo} menuColor="#fff" />
      <Helmet>
        <title>Heeraya Play - Career</title>
      </Helmet>
      <Container>
        <Content>
          <Title>Join Our Team</Title>

          <SubTitle>Our Vision</SubTitle>
          <Description>
            "To revolutionize the way stories are told and shared, leveraging
            the power of short videos. To create a platform where creativity
            thrives, inclusivity is a norm, and privacy is never compromised."
          </Description>

          <SubTitle>Our Mission</SubTitle>
          <Description>
            "Our mission is to provide an empowering platform for everyone to
            express themselves, share their stories and connect with a global
            audience, while feeling safe and respected. We are dedicated to
            creating a space that not only respects privacy but also encourages
            creative freedom and inclusivity."
          </Description>

          <JoinTitle>Join Us</JoinTitle>

          <ApplyButton onClick={onApplyClick}>Apply Now</ApplyButton>
        </Content>
      </Container>
    </>
  );
};

export default Career;
