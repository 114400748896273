export const links = [
  {
    id: 1,
    url: "/",
    text: "home",
  },
  {
    id: 2,
    url: "/contact",
    text: "contact",
  },
  {
    id: 3,
    url: "/career",
    text: "career",
  },
  {
    id: 4,
    url: "/privacy",
    text: "privacy",
  },
  {
    id: 5,
    url: "/terms",
    text: "terms",
  },
];
