import React from "react";
import wallpaper from "../assets/wallpaper.png";
import hero_phone from "../assets/hero_phone.png";
import styled from "styled-components"; 
import Section from "./Section";
import landscape from "../assets/landscape.png";
import portrait from "../assets/portrait.png";
import control from "../assets/control.png";
import Navbar from "./Navbar";
import logo from "../assets/white_logo.png";
import { Helmet } from "react-helmet";


function Home() {
  return (
    <>
      <Navbar logo={logo} menuColor="#fff" />
      <Helmet>
        <title>Heeraya Play</title>
      </Helmet>
      <Container>
        <Left>
          <Wrapper>
            <Top>
              <h2>Introducing</h2>
              <h1>
                Heeraya <b>Play</b>
              </h1>
            </Top>
            <Bottom>
              <h2>Shorts under your control</h2>
              <DownloadButton>
                <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.heeraya.heerayaplay">
                  Download Now
                </a>
              </DownloadButton>
            </Bottom>
          </Wrapper>
        </Left>
        <Right>
          <img src={hero_phone} alt="Two Phones." />
        </Right>
      </Container>
      <Section
        title="Widescreen Wonder"
        tagline="Broaden Your Creative Horizons"
        description="Explore the breadth of your creativity with our Landscape mode. The widescreen format allows for a more detailed and expansive video experience, perfect for encapsulating stunning vistas or dramatic sequences."
        image={landscape}
        direction="LTR"
      />
      <Section
        title="Vertical Vision"
        tagline="Express Yourself in Vertical Elegance"
        description="Craft captivating narratives with our Portrait mode. Designed for on-the-go mobile viewing, this format lets you share immersive stories that connect with audiences on a personal level. Bring your vision to life one frame at a time."
        image={portrait}
        direction="RTL"
      />
      <Section
        title="Master Your Media"
        tagline="Full Control at Your Fingertips"
        description="Experience unprecedented control over your short videos. Our unique platform allows you to manipulate playback speed, skip or replay sections, and more."
        image={control}
        direction="LTR"
      />
    </>
  );
}

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url(${wallpaper});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    background-image: none;
    background-color: #212226;
  }
`;

const Left = styled.div`
  width: 50%;

  @media screen and (max-width: 600px) {

    width: 100%;
    height: 60%;
  }
`;

const Right = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  img {
    width: 60%;
  }

  @media screen and (max-width: 1200px) {
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    img {
      width: 80%;
      align-items: flex-end;
      justify-content: flex-end;
      z-index: 1;
    }
  }
`;

const Top = styled.div`
  width: 80%;
  height: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  color: #d8d9d9;

  h2 {
    font-weight: 100;
    font-size: 3rem;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
  }

  h1 {
    font-weight: 200;
    font-size: 4rem;
    font-family: "Poppins", sans-serif;
  }
  b {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }

  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 2rem;
    }

    h1 {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 600px) {
    height: 100%;
    display: flex;
    align-items: center;
    h2 {
      font-size: 1.5rem;
    }

    h1 {
      font-size: 1.7rem;
    }
  }
`;
const Bottom = styled.div`
  height: 50%;
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-weight: 100;
    font-size: 3rem;
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
    color: #d8d9d9;
  }

  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 600px) {
    height: 40%;
    align-items: center;

    h2 {
      display: none;
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const DownloadButton = styled.button`
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 25px 0px 25px 25px;
  color: #212226;
  font-size: 1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #d8d9d9;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  padding: 15px 25px;
  a {
    color: #212226;
    text-decoration: none;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d8d9d9;
    border-radius: 25px 0px 25px 25px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #212226;
    transition: all 0.3s;
    border-radius: 25px 0px 25px 25px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    background-color: transparent;
    border: 2px solid #d8d9d9;
    &:before {
      width: 100%;
    }
    a {
      text-decoration: none;
      color: white;
    }
  }

  @media (max-width: 1200px) {
    margin-top: 10px;
    border-radius: 20px 0px 20px 20px;
    padding: 10px 20px;
    font-size: 1.3rem;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;


export default Home;
