import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./components/Navbar";
import logo from "./assets/black_logo.png";
import { styled } from "styled-components";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <>
      <Navbar logo={logo} menuColor="#000" />
      <Helmet>
        <title>Heeraya Play - Privacy Policy</title>
      </Helmet>
      <Container>
        <Content>
          <h1 className="title">HEERAYA PLAY – TERMS OF SERVICE</h1>
          <p>
            Welcome to Heeraya Play, which is provided by Heeraya Play, having
            its registered office address at 17089 17th Floor, Block K, Avenue
            GC 14, Greater Noida West, Gautam Buddha Nagar, Uttar Pradesh,
            201009, India. ("<b>Heeraya Play</b>", “<b>We</b>”,” <b>Our</b>”, “
            <b>Us</b>” or ''<b>Heeraya Play</b>”). Heeraya Play is our brand for
            providing and promoting the services. You must carefully read and
            understand these terms and conditions before downloading, installing
            and using the mobile application or desktop version or any version
            available on any mode or medium in the future{" "}
            <b>(together with the “Services” or “Platform”</b>). For the purpose
            of these terms and conditions, any reference to Heeraya Play shall
            include its subsidiaries, affiliates, parent company, and sister
            concerns. These Terms of Service, the Privacy Policy as well as
            Community Guidelines and all other applicable laws and regulations,
            (together <b>“Legal Terms”</b> or <b>“Terms”</b>) govern. Your
            access and use of the Platform, irrespective of whether you are a
            registered user or a visitor (which means that you simply browse the
            platform without limitation, through mobile or other devices like
            computer, or otherwise use the Platform without being registered).
          </p>
          <p>
            You are reading these terms of Agreement (<b>“Terms”</b>) is
            published in accordance with the provisions of the Information
            Technology Act of 2000 read along with its relevant rules including{" "}
            <b>
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules, 2011
              and Information Technology (Intermediary Guidelines and Digital
              Media Ethics Code) Rules, 2021 and amendments if any
            </b>{" "}
            hereby governs the relationship and serve as an agreement between
            you and us and set forth in the Terms by which you may access and
            use the Platform and our related websites, services, applications,
            products and content (collectively, <b>“Services”</b>).
          </p>
          <p>
            By using or accessing or downloading the Platform to view content by
            You, You agree to be bound by these terms.
          </p>

          <ol>
            <li>
              {/* //! -------------1---------------- */}

              <h2>1. GENERAL</h2>
              <ol className="listItems">
                <li>
                  (a) Heeraya Play is a unique mobile and desktop application or
                  software technology that allows You to view, create and share
                  content (<b>“Content”</b>).
                </li>
                <li>
                  (b) For the purpose of this Agreement any reference to Heeraya
                  Play shall include its affiliates, subsidiaries, parent
                  company, and sister concerns.
                </li>
                <li>
                  (c) The terminologies <b>"You", "Your", "User" and "Users"</b>{" "}
                  shall be read in context and shall refer to you.
                </li>
                <li>
                  (d) This Terms of Service, as well as the Privacy Policy,
                  Community Guidelines and other applicable laws and
                  regulations, govern Your access and use of the Platform,
                  irrespective of whether you are a registered User or a visitor
                  (which means that you simply browse the Platform without
                  limitation, through a computer device or mobile or other
                  device, or otherwise use the Platform without being
                  registered).
                </li>
                <li>
                  (e) Heeraya Play hereby grants You a non-exclusive,
                  non-transferable and limited license to access and use the
                  Platform on computer, mobile phones, tablets, portable
                  internet devices, or any other technology/modes/media that may
                  be developed in future.
                </li>
                <li>
                  (f) By accessing and/or using the Platform and/or creating a
                  User profile account on the Platform (an “Account”), you are
                  deemed to have read, accepted, executed and be bound by these
                  terms of this Legal Terms.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------3---------------- */}

              <h2>2. DEFINITION</h2>
              <ol className="listItems">
                <li>
                  (a) “Confidential Information” shall mean any information,
                  including but not limited to processes, methods, systems,
                  business information, technological information, and sales
                  information, client information, which is deemed confidential
                  by Heeraya Play, and is disclosed to the User in any manner.
                </li>
                <li>
                  (b) “Intellectual Property” refers to any invention, creation,
                  work, algorithm, source code, object code or other code,
                  design, confidential information, product and so on which have
                  been acquired, or is in the process of being acquired, or is
                  capable of being acquired as patent, copyright, trademark,
                  trade secret, or any other type of Intellectual Property.
                </li>
                <li>
                  (c) "Content" refers to any and all material/works displayed,
                  including but not limited to videos, images, comments and
                  advertisements.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------3---------------- */}

              <h2>3. Acceptance of Terms </h2>
              <h3>3.1 Acceptance of Terms </h3>
              <ol className="listItems">
                <li>
                  (a) By registering for and/or using the Service in any manner,
                  including but not limited to downloading, installing, visiting
                  or browsing the Platform, You agree to all of the terms and
                  conditions contained herein and all other operating rules,
                  policies and procedures that may be published from time to
                  time on the Platform by Heeraya Play, each of which is
                  incorporated by reference and each of which may be updated by
                  Heeraya Play to time without notice to You in accordance with
                  the terms set out under Terms of Service Privacy Policy
                  Community Guidelines Transparency Report Takedown Policy Help
                  Clause 22 “Modification of Terms” below.
                </li>
                <li>
                  (b) In case of an organization, company or branch of
                  government, You represent and warrant that You have the
                  authority to legally bind Your company or organization and
                  Your company or organization will be bound by the obligations
                  and restrictions of these terms. Any and all references herein
                  to 'You' or 'Your' will include Your company or organization.
                </li>
                <li>
                  (c) This Agreement applies to all users of the Platform,
                  including, without limitation, Users who are contributors of
                  content, information, and other materials or Services on the
                  Platform, individual Users of the Platform, venues that access
                  the Platform, and Users that have a page on the Platform.
                </li>
                <li>
                  (d) In addition, some Services offered through the Platform
                  may be subject to additional terms and conditions specified by
                  Heeraya Play from time to time; Your use of such Services is
                  subject to those additional terms and conditions, which are
                  incorporated into this Agreement by this reference.
                </li>

                <h3>3.2. Eligibility </h3>
                <li>
                  (a) Use and access to the Platform is available to all persons
                  who can be legally bound by contract and who are not
                  disqualified or declared incompetent under the Indian Contract
                  Act, 1872. If You are a minor, i.e., under the age of 18
                  years, You shall not register as a User of the Platform and
                  shall not access or use the Platform. As a minor if You wish
                  to access or use the Platform, such access or use may be made
                  by Your legal guardian or parents.
                </li>
                <li>
                  (b) Heeraya Play reserves the right to terminate such use and/
                  or refuse to provide You with access to the Platform if it is
                  brought to Heeraya Play's notice or if it is discovered that
                  You are under the age of 18 years.
                </li>
                <li>
                  (c) Your account has not been previously disabled for breach
                  of our Terms or Policies or Standards; and
                </li>
                <li>
                  (d) You will comply with these Terms and all applicable
                  domestic and international laws and regulations.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------4---------------- */}

              <h2>
                4. Your Account, User Information and Paid Subscription User
                Charges
              </h2>
              <h3>4.1. Your Account </h3>
              <ol className="listItems">
                <li>
                  You agree that you are solely responsible for the activity
                  that occurs under your account. Please keep your account
                  password confidential and use such an account for strictly
                  personal use only We reserve the right to disable your user
                  account, and remove or disable any content you upload or
                  share, at any time, including if you have failed to comply
                  with any of the provisions of these Terms, or if activities
                  occur on your account which, in our sole discretion, would or
                  might cause damage to or impair the Services or infringe or
                  violate any third party rights, or violate any applicable laws
                  or regulations. Contact us at{" "}
                  <a href="mailto: grievance@heerayaplay.com">
                    grievance@heerayaplay.com
                  </a>
                  &nbsp;with your truthful, clear and valid reason for such
                  appeal.
                </li>
              </ol>

              <h3>4.2. Paid Subscription: </h3>
              <li>
                We may offer certain optional subscription Services based on the
                User’s acceptance of these and other appropriate terms and
                conditions. Upon User acceptance, We reserve the right to charge
                subscription and /or membership fees based on the specific
                optional Service selected by the User. When you purchase a
                subscription, you must provide us with complete and accurate
                payment and other information required by the payment gateway
                provider/ payment system processor. By submitting payment
                details, you promise that you are entitled to purchase a
                subscription using those payment details. If we do not receive
                payment authorization or any authorization is subsequently
                cancelled, we may immediately terminate or suspend your access
                to your subscription based Service on the Platform. We use third
                party payment gateway and payment system provider services for
                receiving payment from Users for its subscription-based
                Services.
              </li>
            </li>

            <li>
              {/* //! -------------5---------------- */}

              <h2>5. Our Ownership and Rights</h2>
              <ol className="listItems">
                <li>
                  5.1. You hereby agree, undertake and confirm that all rights,
                  title and interests in the Platform are retained by Heeraya
                  Play and that this Agreement only licenses Your use of the
                  Platform subject to the terms and conditions of this
                  Agreement. You hereby confirm that this Agreement does not
                  amount to the transfer of any proprietary property by Heeraya
                  Play to You, and that all right, title, and interest in the
                  property continues to vest with Heeraya Play.
                </li>
                <li>
                  5.2. Heeraya Play provides you with the Platform license for
                  free for your personal enjoyment, self-expression, and the
                  possibility of public exposure. In return for the Platform
                  license granted to you herein, you acknowledge and agree that
                  Heeraya Play may generate revenues, increase goodwill, or
                  otherwise increase its value, from your use of the Platform
                  and any User Content you upload thereto, including, by way of
                  example and not limitation, through the sale of advertising,
                  sponsorships, promotions, marketing including content
                  marketing and promotional partnerships off the Platform on any
                  other media and usage data, and you will have no right to
                  share in any such revenue, goodwill or value whatsoever. You
                  further acknowledge that you have no right to receive any
                  income or other consideration from any User Content that you
                  upload onto the Platform, or the use thereof by Heeraya Play
                  and/or other Users as described herein, and that you are
                  prohibited from exercising any rights to monetize or obtain
                  consideration from (i) any User Content uploaded by you or any
                  other User onto the Platform or (ii) any User Content that you
                  upload onto a third-party service via the Platform (e.g., you
                  cannot claim any UGC created and uploaded onto other short
                  video applications via the Platform for monetization).
                </li>
                <li>
                  5.3. If you are a composer or author of a musical work and are
                  affiliated with a performing rights organization, then you
                  must notify your performing rights organization of the
                  royalty-free license you grant through these Terms in your
                  User Content to us. You are solely responsible for ensuring
                  your compliance with the relevant performing rights
                  organization’s reporting obligations. If you have assigned
                  your rights to a music publisher, then you must obtain consent
                  from such music publisher to grant the royalty-free license(s)
                  set forth in these Terms in your User Content or have such
                  music publisher enter into these Terms with us.
                </li>
                <li>
                  5.4. Authoring a musical work (e.g., wrote a song) does not
                  necessarily give you the right to grant us the licenses in
                  these Terms. If you are a recording artist under contract with
                  a record label, then you are solely responsible for ensuring
                  that your use of the Services is in compliance with any
                  contractual obligations you may have to your record label,
                  including if you create any new recordings 5.4. Authoring a
                  musical work (e.g., wrote a song) does not necessarily give
                  you the right to grant us the licenses in these Terms. If you
                  are a recording artist under contract with a record label,
                  then you are solely responsible for ensuring that your use of
                  the Services is in compliance with any contractual obligations
                  you may have to your record label, including if you create any
                  new recordings through the Services that may be claimed by
                  your label.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------6---------------- */}

              <h2>6. Your License and Use of the Platform/Services</h2>
              <ol className="listItems">
                <li>
                  6.1. Subject to the Terms Heeraya Play hereby grants You only
                  a limited, non-transferable, non-exclusive, revocable license
                  to make personal and non-commercial use of the Platform.
                </li>
                <li>
                  6.2. Services offered by Heeraya Play through the Platform,
                  may require prior registration by the User. To gain access to
                  the Services, You will be required to create an account by
                  completing the registration process. As part of such a
                  registration process, You will be required to provide Us with
                  current, complete and accurate information pertaining to such
                  details as prompted by the applicable registration form. You
                  will also be required to choose a unique password and a
                  username, which shall be entered by You every time You wish to
                  gain access to the said Services. You shall not be entitled to
                  gain, and shall not facilitate, access to the Platform through
                  a single account and password, by multiple users on a network.
                  You agree and understand that We do not permit any part of the
                  Platform being cached in proxy servers. You are entirely
                  responsible for maintaining the confidentiality of Your
                  password and account. You agree that you are solely
                  responsible (to us and to others) for the activity that occurs
                  under your account. When creating an account, you must provide
                  accurate information about yourself and create only one
                  account for strictly personal purposes.
                </li>
                <li>
                  6.3. You shall not share your account information and password
                  with others or use anyone else’s account. You are entirely
                  responsible for any and all activities that occur under Your
                  account. You agree to notify Us immediately of any
                  unauthorized use of Your account or any other breach of
                  security. In such an event, we shall do Our best to help you
                  recover your account; however, we cannot guarantee that Your
                  account will be restored or the content contained therein will
                  be recovered. We will not be liable for any loss that You may
                  incur as a result of unauthorized use of Your password or
                  account. However, you could be held liable for losses incurred
                  by the Company or another party due to someone else using your
                  account or password.
                </li>
                <li>
                  6.4. Registration through Facebook: You may also register for
                  the Services by using your Facebook username and password
                  (“Facebook Connect”). If, however, you are under 18 years of
                  age, you may log in to the Services using Facebook Connect and
                  utilize the Services only under the supervision of your parent
                  or legal guardian. Using Facebook Connect allows us to
                  personalize and enhance your experience while using the
                  Services, based on your personal information, profile, likes,
                  and other relevant information. When you use this feature, you
                  expressly consent to information about your activity on the
                  Services. You may control the information being shared through
                  Facebook Connect by changing your account / privacy settings.
                  You shall be solely responsible for using this feature and any
                  related compliances with the terms of your Facebook account.
                  By registering through Facebook, you agree to the terms stated
                  herein and in addition to any other specific terms which shall
                  be posted at an appropriate location of the Platform. Each
                  registration is for a single individual user only.
                </li>
                <li>
                  6.5. Your access to and use of the Services is subject to
                  these Terms and all applicable laws and regulations. You may
                  not:
                </li>
                <li className="nestedListItem">
                  ● access or use the Services if you are not fully able and
                  legally competent to agree to these Terms;
                </li>
                <li className="nestedListItem">
                  ● carry out any activity during the access or use of our
                  Services which is unlawful, misleading, discriminatory or
                  fraudulent in any way.
                </li>
                <li className="nestedListItem">
                  ● make unauthorized copies modify, adapt, translate, Heeraya
                  Play engineer, disassemble, decompile or create any derivative
                  works of the Services or any content included therein,
                  including any files, tables or documentation (or any portion
                  thereof) or determine or attempt to determine any source code,
                  algorithms, methods or techniques embodied by the Services or
                  any derivative works thereof;
                </li>
                <li className="nestedListItem">
                  ● distribute, license, transfer, or sell, in whole or in part,
                  any of the Services or any derivative works thereof;
                </li>
                <li className="nestedListItem">
                  ● market, rent or lease the Services for a fee or charge, or
                  use the Services to advertise or perform any commercial
                  solicitation;
                </li>
                <li className="nestedListItem">
                  ● use the Services, without our express written consent, for
                  any commercial or unauthorized purpose, including
                  communicating or facilitating any commercial advertisement or
                  solicitation or spamming;
                </li>
                <li className="nestedListItem">
                  ● interfere with or attempt to interfere with the proper
                  working of the Services, disrupt our website or any networks
                  connected to the Services, or bypass any measures we may use
                  to prevent or restrict access to the Services;
                </li>
                <li className="nestedListItem">
                  ● incorporate the Services or any portion thereof into any
                  other program or product. In such case, we reserve the right
                  to refuse service, terminate accounts or limit access to the
                  Services in our sole discretion;
                </li>
                <li className="nestedListItem">
                  ● use automated scripts to collect information from or
                  otherwise interact with the Services;
                </li>
                <li className="nestedListItem">
                  ● impersonate any person or entity, or falsely state or
                  otherwise misrepresent you or your affiliation with any person
                  or entity, including giving the impression that any content
                  you upload, post, transmit, distribute or otherwise make
                  available emanates from the Services;
                </li>
                <li className="nestedListItem">
                  ● intimidate or harass another, or promote sexually explicit
                  material, violence or discrimination based on race, sex,
                  religion, nationality, disability, sexual orientation or age;
                </li>
                <li className="nestedListItem">
                  ● use the Services in a manner that may create a conflict of
                  interest or undermine the purposes of the Services, such as
                  trading reviews with other Users or writing or soliciting fake
                  reviews;
                </li>
                <li className="nestedListItem">
                  ● use the Services to upload, transmit, distribute, store or
                  otherwise make available in any way:
                </li>
                <li className="nestedListItem">
                  ● files that contain viruses, trojans, worms, logic bombs or
                  other material that is malicious or technologically harmful;
                </li>
                <li className="nestedListItem">
                  ● any unsolicited or unauthorized advertising, solicitations,
                  promotional materials, “junk mail,” “spam,” “chain letters,”
                  “pyramid schemes,” or any other prohibited form of
                  solicitation.
                </li>
                <li className="nestedListItem">
                  ● any private information of any third party, including
                  addresses, phone numbers, email addresses, number and feature
                  in the personal identity document (e.g., National Insurance
                  numbers, passport numbers) or credit card numbers;
                </li>
                <li className="nestedListItem">
                  ● any material which does or may infringe any copyright,
                  trademark or other intellectual property or privacy rights of
                  any other person;
                </li>
                <li className="nestedListItem">
                  ● any material which is defamatory of any person, obscene,
                  offensive, pornographic, hateful or inflammatory;
                </li>
                <li className="nestedListItem">
                  ● any material that would constitute, encourage or provide
                  instructions for a criminal offence, dangerous activities or
                  self-harm;
                </li>
                <li className="nestedListItem">
                  ● any material that is deliberately designed to provoke or
                  antagonize people, especially trolling and bullying, or is
                  intended to harass, harm, hurt, scare, distress, embarrass or
                  upset people;
                </li>
                <li className="nestedListItem">
                  ● any material that contains a threat of any kind, including
                  threats of physical violence;
                </li>
                <li className="nestedListItem">
                  ● any material that is racist or discriminatory, including
                  discrimination on the basis of someone’s race, religion, age,
                  gender, disability or sexuality;
                </li>
                <li className="nestedListItem">
                  ● any answers, responses, comments, opinions, analysis or
                  recommendations that you are not properly licensed or
                  otherwise qualified to provide;
                </li>
                <li>
                  6.6. Removal of Content By Heeraya Play / Imposition of Ban on
                  a User Profile:
                </li>
                <p>
                  In addition to the above, your access to and use of the
                  Services must, at all times, be compliant with Our Community
                  Guidelines.
                </p>
                <p>
                  We reserve the right, at any time and without prior notice, to
                  remove or disable access to content at our discretion for any
                  reason or no reason. Some of the reasons we may remove or
                  disable access to content may include finding the content
                  objectionable, in violation of these Terms, or otherwise
                  harmful to the Services or our users. Our automated systems
                  analyse your content to provide you with personally relevant
                  product features, such as customized search results, tailored
                  advertising, and spam and malware detection. This analysis
                  occurs as the content is sent, received, and when it is
                  stored.
                </p>
                <p>
                  Your usage of our Platform is governed by the Terms and
                  Community Guidelines. If any of our users report your content
                  which violates the Terms and Community Guidelines, we may
                  remove such content from our Platform. In the event that
                  multiple reports are made regarding violation of the terms and
                  Community Terms of Service Privacy Policy Community Guidelines
                  Transparency Report Takedown Policy Help Guidelines, we may be
                  compelled to terminate your account with us and block you from
                  registering with us.
                </p>
                <p>
                  If you wish to appeal any such removal, you may write to us at{" "}
                  <a href="mailto: grievance@heerayaplay.com">
                    grievance@heerayaplay.com
                  </a>
                </p>
                <li style={{ marginTop: "20px" }}>
                  6.7. The Platform is licensed, not sold, to You, even after
                  installation on Your devices. Heeraya Play may assign this
                  License Agreement or any part of it without restrictions. You
                  are not allowed to assign, transfer or sublicense Your rights
                  under this license, to any third party.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------7---------------- */}

              <h2>7. Content: Our Content </h2>
              <ol className="listItems">
                <li>
                  7.1. Users of the Services may be permitted to upload, post or
                  transmit (such as via a stream) or otherwise make available
                  content through the Services including, without limitation,
                  any text, photographs, user videos, sound recordings and the
                  musical works embodied therein, including videos that
                  incorporate locally stored sound recordings from your personal
                  music library and ambient noise ( collectively “User
                  Content”). Users of the Services may also extract all or any
                  portion of User Content created by another user to produce
                  additional User Content, including collaborative User Content
                  with other users, that combine and intersperse User Content
                  generated by more than one user. Users of the Services may
                  also overlay music, graphics, stickers, and other elements
                  provided by Service onto this User Content and transmit this
                  User Content through the Services. The views expressed by
                  other users on the Services do not represent our views or
                  values.
                </li>
                <li>
                  7.2. You understand that you are the owner and responsible for
                  User Content that you post, upload, transmit, share or
                  otherwise make available on, through or in connection with the
                  Services. You agree not to use the Services to take any action
                  that could harm us or anyone else, interfere with the
                  Services, or use the Services in a manner that violates any
                  applicable law. You warrant that the User Content you submit
                  to us does not infringe any patent, trademark, trade secret,
                  copyright, or other intellectual or proprietary or privacy
                  right of any third party or individual and does not contain
                  any obscene or offensive material. As a condition of your
                  access to and use of the Services, you agree not to use the
                  Services to infringe on any intellectual property rights. We
                  reserve the right, with or without notice, at any time and in
                  our sole discretion to block access to and/or terminate the
                  accounts of any user who infringes or is alleged to infringe
                  any copyrights or other intellectual property rights. For use
                  of any third party's intellectual property, You may need to
                  get permission directly from the owner of the intellectual
                  property.
                </li>
                <li>
                  7.3. Any User Content will be considered non-confidential and
                  non-proprietary. You must not post any User Content on or
                  through the Services or transmit to us any User Content that
                  you consider to be confidential or proprietary. When you
                  submit User Content through the Services, you agree and
                  represent that you own that User Content, or you have received
                  all necessary permissions, clearances from, or are authorized
                  by, the owner of any part of the content to submit it to the
                  Services, to transmit it from the Services to other
                  third-party platforms, and/or adopt any third-party content.
                </li>
                <li>
                  7.4. If you only own the rights in and to a sound recording,
                  but not to the underlying musical works embodied in such sound
                  recordings, then you must not post such sound recordings to
                  the Services unless you have all permissions, clearances from,
                  or are authorized by, the owner of any part of the content to
                  submit it to the Services.
                  <b>
                    NO RIGHTS ARE LICENSED WITH RESPECT TO SOUND RECORDINGS AND
                    THE MUSICAL WORKS EMBODIED THEREIN THAT ARE MADE AVAILABLE
                    FROM OR THROUGH THE SERVICE.
                  </b>
                </li>
                <li>
                  7.5. We do not endorse, support, represent or guarantee the
                  completeness, truthfulness, accuracy, or reliability of any
                  user-generated Content or endorse any opinions expressed
                  thereof. You understand that by using the Services, you may be
                  exposed to Content that might be offensive, harmful,
                  inaccurate or otherwise inappropriate, or in some cases,
                  postings that have been mislabelled or are otherwise
                  deceptive. All Content is the sole responsibility of the
                  person who originated such Content.
                </li>
                <li>
                  7.6. You shall not commit or engage in, or encourage, induce,
                  solicit or promote, any conduct that would constitute a
                  criminal offence, give rise to civil liability or otherwise
                  violate any law or regulation, or use the Platform for any
                  illegal or unauthorized purpose.
                </li>
                <li>
                  7.7. You further grant us a royalty-free license to use your
                  username, image, voice, and likeness to identify you as the
                  source of any of your User Content.
                </li>
                <li>
                  7.8. By accepting the terms and conditions, you accept that
                  you provide us with content, or view content provided by
                  others, you are doing so at your own discretion and risk
                  including accuracy, completeness of this content. Heeraya Play
                  possesses all the rights to action takedown or ban the content
                  that violates our Terms.
                </li>
                <li>
                  7.9. We reserve the right, at any time and without prior
                  notice, to remove or disable access to content at our
                  discretion for any reason or no reason. Some of the reasons we
                  may remove or disable access to content may include finding
                  the content objectionable, in violation of these Terms or our
                  Community Guidelines, or otherwise harmful to the Services or
                  our users. Our automated systems analyze your content to
                  provide you personally relevant product features, such as
                  customized search results, tailored advertising, and spam and
                  malware detection. This analysis occurs as the content is
                  sent, received, and when it is stored. Terms of Service
                  Privacy Policy Community Guidelines Transparency Report
                  Takedown Policy Help.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------8---------------- */}

              <h2>8. Intellectual Property Rights and License</h2>
              <ol className="listItems">
                <li>
                  8.1. Your use of the Platform is, and at all times shall be,
                  governed by and subject to the laws regarding copyright,
                  trademark, patent, and trade secret and use of intellectual
                  property. You shall abide by laws regarding copyright,
                  trademark, patent, and trade secret ownership and use of
                  intellectual property, and You shall be solely responsible for
                  any violations of any laws and for any infringements of any
                  intellectual property rights caused by Your use of the
                  Platform through Your device. As a condition of your access to
                  and use of the Services, you agree not to use the Services to
                  infringe on any intellectual property rights. We reserve the
                  right, with or without notice, at any time and in our sole
                  discretion to block access to and/or terminate the accounts of
                  any user who infringes or is alleged to infringe any
                  copyrights or other intellectual property rights. For use of
                  any third party's intellectual property, You may need to get
                  permission directly from the owner of the intellectual
                  property.
                </li>
                <li>
                  8.2. Our IP: All trademarks, brands and service marks of the
                  Platform are the property of or licensed to Heeraya Play.
                  Heeraya Play owns all of the copyrights and databases in
                  relation to the Platform. The content included on this
                  website, including, but not limited to the Heeraya Play
                  reports, text, graphics, logos, icons, and images is the
                  exclusive property of Heeraya Play and other respective owners
                  that have granted Heeraya Play the right and license to use
                  such property and is protected by Indian copyright laws. All
                  trademarks, service marks, and trade names are proprietary to
                  Heeraya Play or other respective owners that have granted
                  Heeraya Play the right and license to use such marks. The
                  Platform and any underlying technology or software used in
                  connection with the Platform may contain rights of Heeraya
                  Play or its business associates, affiliates or any third
                  party. The Platform is licensed, not sold, to You, even after
                  installation on Your devices. Heeraya Play may assign this
                  license agreement or any part of it without restrictions. You
                  are not allowed to assign, transfer or sub-license Your rights
                  under this license, to any third party.
                </li>
                <li>
                  8.3. Any intellectual property which is not specifically
                  mentioned to be owned by Heeraya Play is owned by their
                  respective owners and the owners have a right to take
                  appropriate actions against You for any violation,
                  infringement or passing off. You shall not download or
                  encourage others to download copyrighted works, trademarks, or
                  other proprietary information without obtaining the prior
                  written consent of the owner/exclusive licensee of the
                  Intellectual Property. In the event of infringement, Heeraya
                  Play may at its own discretion or as required by law take
                  necessary steps to remedy the same.
                </li>
                <li>
                  8.4. You continue to own your Content and grant us license of
                  the Content which You may upload, post or transmit (such as
                  via a stream) or otherwise make available content through the
                  Services including, without limitation, any text, photographs,
                  videos, sound recordings. You or the owner of your User
                  Content still own the copyright in User Content sent to us,
                  but by submitting User Content via the Services, you hereby
                  grant us an unconditional irrevocable, non-exclusive,
                  royalty-free, fully transferable, perpetual worldwide license
                  to use, adapt, of, publish and/or transmit, and/or distribute
                  and to authorize other users of the Services and other third
                  parties to view, access, use, download, adapt, of, publish
                  and/or transmit your User Content in any format and on any
                  platform.
                </li>
                <li>
                  8.5. You hereby grant Heeraya Play a worldwide, royalty-free,
                  perpetual, non-exclusive, irrevocable, non-transferable,
                  assignable, sub-licensable, right and license to access, make
                  personal use of the Content and unlimited license to make
                  copies of, reproduce, adapt, make derivative works of,
                  commercially exploit, communicate to the public, broadcast,
                  and make available Your Content and the Content made available
                  by You on the Platform. You understand and agree that Heeraya
                  Play may exercise these rights on the Content on the Platform
                  or on other platforms, applications, social media pages or any
                  other device or display/communication means under its control.
                  You further, agree and accept that we may use Your Content, on
                  any third-party platform in any manner not expressly permitted
                  hereunder, we need no explicit permission or consent from You,
                  for sharing and broadcasting Your Content and other materials
                  on various third-party platforms including but not limited to
                  social media channels and other third-party sites and
                  services, including, for example, Instagram, Facebook,
                  YouTube, Twitter any third-party Platform such as media
                  channels etc. We are also not responsible for putting you in
                  touch with any third-party owner for the above-mentioned
                  purposes. You represent that You are authorized to grant these
                  rights to Heeraya Play in accordance with this Agreement and
                  policies of this Platform, and all applicable laws and
                  regulations. You acknowledge, confirm and agree that all
                  Intellectual Property rights in the Content You post vests
                  with You or You have a valid license to post, display,
                  reproduce, make copies, broadcast, communicate to the public
                  such Content on the Platform.
                </li>
                <li>
                  8.6. Content Distribution: You understand and agree that
                  Heeraya Play has an exclusive, worldwide, royalty-free,
                  perpetual, unlimited and unrestricted license to make copies
                  of, communicate to the public, broadcast, and make available
                  your Content and the Content made available by You on the
                  Platform and off the Platform (via third party
                  platforms/media) for both commercial and non-commercial
                  purposes. You understand and agree that the licensing rights
                  granted to Heeraya Play extend to the intellectual property
                  rights (including but not limited to copyright, trademark,
                  designs and patent) subsisting in the content created by You
                  on the Platform.
                </li>
                <li>
                  8.7. Your content remains yours, which means that you retain
                  any intellectual property rights that you have in your
                  content.
                </li>
                <li>
                  8.8. You understand and agree that Heeraya Play reserves the
                  right to distribute the User Content to its business
                  associates, affiliates, third parties and to any other
                  entity(ies) that it deems necessary at its own discretion.
                </li>
                <li>
                  8.9. By posting Content on the Platform, You confirm that You
                  are the owner of all Content and underlying works contained
                  therein, or are authorised by the owner to make the Content
                  available through the Platform.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------9---------------- */}

              <h2>9. Confidentiality</h2>
              <ol className="listItems">
                <li>
                  9.1. You hereby agree that You shall not disclose any
                  confidential information to any third party about the Platform
                  or Heeraya Play which You have come into possession of by
                  virtue of downloading, installing, accessing or using the
                  Platform, or in any other manner. You also agree that in any
                  event, if such confidential information is disclosed by You in
                  violation of this Agreement, Heeraya Play may, on its own sole
                  discretion, terminate this Agreement by deleting Your
                  Account(s), without any notice to You, or to take any other
                  legal measure which is considered reasonable and legitimate.
                </li>
                <li>
                  9.2. In the event You are aware that the confidential
                  information is passed to any third party, You shall
                  immediately inform Heeraya Play regarding the same.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------10---------------- */}

              <h2>10. User’s Obligations/Rules and Regulations</h2>
              <p>
                The Platform, Platform content and any User Content belonging to
                another User may not be used, modified, reproduced, duplicated,
                copied, published, distributed, downloaded, sold, resold,
                transformed, redesigned, reconfigured, retransmitted, or
                otherwise exploited by you for any purpose except as facilitated
                by the tools, products, services, functionalities and/or
                features made available to Users of the Platform, or without the
                express prior written consent of Heeraya Play, the applicable
                User, and/or the applicable third-party owner thereof, in each
                instance.
              </p>
              <ol className="listItems">
                <li>
                  10.1. As a condition of use, You promise not to use the
                  Platform for any purpose that is prohibited by this Agreement.
                  You are responsible for all of Your activity in connection
                  with the Platform.
                </li>
                <li>
                  10.2. You agree, undertake and confirm that You are only
                  permitted to use the Platform for accessing Content and for
                  sharing Content created by You or Content which You are
                  authorised to post on the Platform.
                </li>
                <li>
                  10.3. You hereby acknowledge and concede that the use, access
                  and availing the facilities of the Platform shall be governed
                  by the Terms of Service, Privacy Policy and Community
                  Guidelines of Heeraya Play and You shall act in accordance
                  with the said documents.
                </li>
                <li>
                  10.4. Additionally, You shall abide by all applicable laws and
                  regulations and if you represent a business, any advertising,
                  marketing, privacy, or other self-regulatory code(s)
                  applicable to your industry.
                </li>
                <li>
                  10.5. You will not host, display, upload, modify, publish,
                  transmit, store, update or share any content that:
                </li>
                <li className="nestedListItem">
                  (i) belongs to another person and to which the user does not
                  have any right;
                </li>
                <li className="nestedListItem">
                  (ii) is defamatory, obscene, pornographic, podophilic,
                  invasive of another’s privacy, including bodily privacy,
                  insulting or harassing on the basis of gender, libellous,
                  racially or ethnically objectionable, relating or encouraging
                  money laundering or gambling, or otherwise inconsistent with
                  or contrary to the laws in force;
                </li>
                <li className="nestedListItem">
                  (iii) is harmful to children ;
                </li>
                <li className="nestedListItem">
                  (iv) infringes any patent, trademark, copyright or other
                  proprietary rights;
                </li>
                <li className="nestedListItem">
                  (v) violates any law for the time being in force;
                </li>
                <li className="nestedListItem">
                  (vi) deceives or misleads the addressee about the origin of
                  the message or knowingly and intentionally communicates any
                  information which is patently false or misleading in nature
                  but may reasonably be perceived as a fact;
                </li>
                <li className="nestedListItem">
                  (vii) impersonates another person;
                </li>
                <li className="nestedListItem">
                  (viii) threatens the unity, integrity, defense, security or
                  sovereignty of India, friendly relations with foreign states,
                  or public order, or causes incitement to the commission of any
                  cognizable offence or prevents investigation of any offence or
                  is insulting other nation;
                </li>
                <li className="nestedListItem">
                  (ix) contains a software virus or any other computer code,
                  file or program designed to interrupt, destroy or limit the
                  functionality of any computer resource;
                </li>
                <li className="nestedListItem">
                  (x) is patently false and untrue, and is written or published
                  in any form, with the intent to mislead or harass a person,
                  entity, or agency for financial gain or to cause any injury to
                  any person;
                </li>
                <li className="nestedListItem">
                  (xi) is in any manner, likely to adHeeraya Playly affect
                  Heeraya Play’s business interests;
                </li>
                <li className="nestedListItem">
                  (xii) in any manner damages or degrades the Platform or cause
                  impairment of the performance, availability or accessibility
                  of the Platform;
                </li>
                <li className="nestedListItem">
                  (xiii) depicts advertisement, promotions or solicitations of
                  business or solicits Users or any other form of solicitation;
                </li>
                <li className="nestedListItem">
                  (xiv) posts or transmits any communication or solicitation
                  designed or intended to obtain the password, account,
                  sensitive personal information or any other personal
                  information from any other User;
                </li>
                <li className="nestedListItem">
                  (xv) hack or interfere with the Platform, its servers or any
                  connected networks
                </li>
                <li className="nestedListItem">
                  (xvi) posts or transmits spam, including but not limited to
                  unsolicited or unauthorized advertising, promotional
                  materials, or informational announcements and; and
                </li>
                <li className="nestedListItem">
                  (xvii) displays news/current affairs content.
                </li>
                <li>
                  10.6. You shall not indulge in any of the following prohibited
                  activities –
                </li>
                <li className="nestedListItem">
                  (i) You shall not illegally download/save the content accessed
                  through the Platform at any given time, or circumvent any
                  technological measures employed by Heeraya Play to prevent
                  unauthorized access to or copying of content or any part of
                  the Platform.
                </li>
                <li className="nestedListItem">
                  (ii) You shall not alter or modify any part of the Platform
                  and/or use the Platform for any illegal purpose.
                </li>
                <li className="nestedListItem">
                  (iii) You shall not reformat or frame any portion of any web
                  page that is part of the Platform.
                </li>
                <li className="nestedListItem">
                  (iv) You shall not collect or harvest or attempt to collect
                  sensitive personal data, personal information or any other
                  kind of information about other users, including without
                  limitation, through spidering or any form of scraping.
                </li>
                <li className="nestedListItem">
                  (v) You shall not deliberately impersonate another person,
                  whether real or imaginary or otherwise misrepresent Your
                  affiliation with a person or entity, for example, by
                  registering an account in the name of another person or
                  company or sending messages or making comments using the name
                  of another person or impersonating other Users or otherwise
                  faking Your identity.
                </li>
                <li className="nestedListItem">
                  (vi) You shall not rent, sell or transfer or lease or offer to
                  sell or transfer access to the Platform and/or any Heeraya
                  Play account or permit any third parties to use Your name and
                  password, or any Content on the Platform.
                </li>
                <li className="nestedListItem">
                  (vii) You shall not resell or commercially use the Platform or
                  any of its content or download or copy account information for
                  the benefit of Yourself or any third-party. The Platform is
                  for personal use only and may not be reproduced, duplicated,
                  copied, sold, resold, visited, or otherwise exploited for any
                  commercial purpose.
                </li>
                <li className="nestedListItem">
                  (viii) You shall not authorize any third party to use Your
                  account.
                </li>
                <li className="nestedListItem">
                  (ix) You shall not commit or engage in, or encourage, induce,
                  solicit or promote, any conduct that would constitute a
                  criminal offence, give rise to civil liability or otherwise
                  violate any law or regulation; or use the Platform for any
                  illegal or unauthorized purpose; You agree to comply with all
                  local laws applicable to Your conduct and the content and
                  information, including hyperlinks, that You upload, store,
                  share or transmit using the Platform.
                </li>
                <li className="nestedListItem">
                  (x) You shall not alter or remove, attempt to alter or remove
                  any trademark, copyright or other proprietary or legal notices
                  contained in, or appearing on the Platform or on any Content
                  appearing on the Platform. You may not frame or utilize
                  framing techniques to enclose any trademark, logo, or other
                  proprietary information (including images, content, music,
                  text, page layout, or form) of Heeraya Play and Our affiliates
                  or other Users. You may not use any meta-tags or any other
                  "hidden text" utilizing Heeraya Play's name or trademarks
                  without the express written consent of Heeraya Play. You may
                  not use any Heeraya Play logo or other proprietary graphic or
                  trademark as part of the link without Heeraya Play's advance
                  express written permission. Any unauthorized use terminates
                  the permission or license granted by Heeraya Play.
                </li>
                <li className="nestedListItem">
                  (xi) You shall not employ scraping or similar techniques to
                  aggregate, repurpose, adapt, copy, republish, make available
                  or otherwise communicate to the public, display, perform,
                  transfer, share, distribute or otherwise use or exploit the
                  content except via the features provided in the Platform.
                </li>
                <li className="nestedListItem">
                  (xii) You shall not permit any third party to copy or adapt
                  the object code of the Platform, or Heeraya Play engineer,
                  Heeraya Play assemble, decompile, modify or attempt to
                  discover any source or object code of any part of the
                  Platform, or circumvent or attempt to circumvent or copy any
                  copy protection mechanism or access any rights management
                  information pertaining to the content or Platform.
                </li>
                <li className="nestedListItem">
                  (xiii) You shall not transmit any viruses, worms, defects,
                  Trojan horse, cancelbots, spyware, other items of a
                  contaminating or destructive nature, adware, packet or IP
                  spoofing, forged routing or electronic mail address
                  information or similar methods or technology, harmful code,
                  flood pings, malware, bot, time bomb, worm, or another harmful
                  or malicious component, which might overburden, impair or
                  disrupt the Platform or networks forming part of, or connected
                  to, the Platform, or which does or might restrict or inhibit
                  any other user's use and enjoyment of the Platform.
                </li>
                <li className="nestedListItem">
                  (xiv) You shall not stalk, exploit, threaten, abuse or
                  otherwise harass another User, or any Heeraya Play employees
                  and/or affiliates.
                </li>
                <li className="nestedListItem">
                  (xv) You shall not violate, circumvent or attempt to violate
                  or circumvent any data security measures employed by Heeraya
                  Play; access or attempt to access data or materials which are
                  not intended for Your use; log into, or attempt to log into an
                  account which You are not authorized to access; attempt to
                  scan or test the Terms of Service Privacy Policy Community
                  Guidelines Transparency Report Takedown Policy Help
                  vulnerability of Heeraya Play's server, system or network or
                  attempt to breach Heeraya Play's data security or
                  authentication procedures and;
                </li>
                <li className="nestedListItem">
                  (xvi) You shall not use any robot, spider, offline readers,
                  site search and/or retrieval application, or other device to
                  retrieve or index any portion of the Platform, with the
                  exception of public search engines; use any robot, spider,
                  scraper or other automated means to access, analyze or copy
                  the Platform and/or information ( whether Our information or
                  other User's information ).
                </li>
                <li>
                  10.7. You shall strictly abide by the Community Guidelines and
                  the Privacy Policy.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------11---------------- */}

              <h2>11. Representations and Warranties</h2>
              <ol className="listItems">
                <li>
                  11.1. OTHER THAN AS EXPRESSLY STATED IN THIS TERMS OR AS
                  REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS” AND HEERAYA
                  PLAY DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES
                  ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES
                  ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE
                  SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY,
                  RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR
                  (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE
                  SERVICE.
                </li>
                <li>
                  11.2. The content accessible by You through Your use of the
                  Platform includes materials that belong to third parties. You
                  acknowledge that Heeraya Play assumes no responsibility for
                  such Content. You understand that You may encounter offensive,
                  indecent, or other objectionable content while using the
                  Platform and that You may be involuntarily exposed to such
                  offensive and obscene materials. It also is possible for
                  others to obtain personal information about You due to Your
                  use of the Platform, and that the recipient may use such
                  information to harass or injure You. Heeraya Play does not
                  approve of such unauthorized use, but by using the Platform
                  You acknowledge and agree that Heeraya Play is not responsible
                  for any such illegitimate use of any personal information so
                  obtained by others.
                </li>
                <li>
                  11.3. Heeraya Play makes no representations or guarantee that
                  the Platform will be free from loss, destruction, damage, any
                  unauthorized access to or use of Heeraya Play's secure servers
                  and/or any and all personal information and/or financial
                  information stored therein, corruption, attack, any
                  interruption or cessation of transmission to or from the
                  Platform, any bugs, viruses, Trojan horses, or the like which
                  may be transmitted to or through the Platform by any third
                  party, and/or any errors or omissions in any Content or for
                  any loss or damage of any kind incurred as a result of the use
                  of any of the Content accessible via the Platform.
                </li>
                <li>
                  11.4. Heeraya Play makes no guarantees, representations, or
                  warranties that Content accessible through the Platform by the
                  User or the links provided by third parties will be free of
                  viruses or similar contamination or destructive features. You
                  agree that You assume all risk as to the quality and
                  performance of the Platform and the accuracy and completeness
                  of the Content.Terms of Service Privacy Policy Community
                  Guidelines Transparency Report Takedown Policy Help
                </li>
                <li>
                  11.5. Heeraya Play does not warrant, endorse, guarantee, or
                  assume responsibility for any product or service advertised or
                  offered by a third party through the use of the Platform or
                  any hyperlinked services or featured in any banner or other
                  advertising, and Heeraya Play will not be a party to or in any
                  way be responsible for monitoring any transaction between You
                  and third-party providers of products or services. As with the
                  purchase of a product or service through any medium or in any
                  environment, You should use best judgment and exercise
                  caution, interference, personal injury or property damage, of
                  any nature whatsoever, resulting from the access to and use of
                  the Platform, hacking, or other security intrusion by You, and
                  Heeraya Play disclaims any liability relating thereto.
                </li>
                <li>
                  11.6. Heeraya Play makes no guarantees, representations, or
                  warranties that use or results of the Platform will be
                  accurate, timely, reliable, uninterrupted, or without errors.
                  Without prior notice, Heeraya Play may modify, suspend, or
                  discontinue any part or all of the Platform or Your use of the
                  Platform. In such an event, Heeraya Play will not be liable to
                  You or any third party.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------12---------------- */}

              <h2>12. Advertisements </h2>
              <ol className="listItems">
                <li>
                  12.1. The Platform may provide access to third-party content
                  and/or websites that are not owned or controlled by Heeraya
                  Play.
                </li>
                <li>
                  12.2. You hereby agree, undertake and confirm to receive
                  advertisements through use of this Platform.
                </li>
                <li>
                  12.3. The Platform may provide access to third-party games,
                  quizzes, and other such activities requiring skill, for which
                  prizes may be awarded by the concerned third-party. Heeraya
                  Play does not own or control these third-party games or other
                  activities, and does not control or undertake any liability
                  for declaring results or awarding prizes.
                </li>
                <li>
                  12.4. Heeraya Play is not responsible for any third-party
                  content, including but not limited to texts, graphics, user
                  interfaces, visual interfaces, photographs, trademarks, logos,
                  sounds, music and artwork or applications, services,
                  advertisements, and/or links that may be contained in the
                  Platform.
                </li>
                <li>
                  12.5. If You have any complaints or concerns regarding
                  third-party content or third-party activities on the Platform,
                  You agree to report your complaints through the Grievance
                  redressal mechanism mentioned in the Mechanism for filing
                  Complaints(insert link).You further agree and acknowledge that
                  Heeraya Play will handle Your complaints in accordance with
                  its grievance redressal mechanism and the applicable law.
                </li>
                <li>
                  12.6. You shall not copy reproduce, republish, upload, post,
                  publicly display, encode, translate, transmit, download or
                  distribute the Platform or any Content in any way (including
                  "mirroring") to any other computer, server, website or other
                  medium for publication or distribution or for any commercial
                  enterprise. Heeraya Play shall have all the rights to take
                  necessary action and claim damages in case of any violation.
                  Such action may also involve terminating Your permission to
                  use the Platform by deleting Your Account.
                </li>
                <li>
                  12.7. You may use general information about the Platform
                  expressly permitted, provided that You (1) do not remove any
                  proprietary notice language in all copies of such documents,
                  (2) use such Content only for Your Personal non-commercial
                  informational purpose unless agreed otherwise through an
                  agreement and do not copy or post such Content on any
                  networked computer or broadcast it in any media, (3) make no
                  modifications to any such Content, and (4) do not make any
                  additional representations or warranties relating to such
                  documents.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------13---------------- */}

              <h2>13. Use of Platform</h2>
              <ol className="listItems">
                <li>
                  13.1. You agree and acknowledge that Heeraya Play and the
                  Platform is only a facilitator and is not and cannot be a
                  party to or control in any manner any transactions on the
                  Platform. Accordingly, the contract of sale of products on the
                  Platform shall be strictly directly only between You and the
                  sellers/ merchants on the Platform.
                </li>
                <li>
                  You may not use any products available on the Platform for any
                  illegal or unauthorized purpose;
                </li>
                <li>
                  Any fraudulent use of this Platform or applicable payment
                  method to purchase the products from the Platform, which
                  causes any monetary loss to Heeraya Play as a result of Your
                  action/inaction shall be recovered from You. Without prejudice
                  to the above, Heeraya Play reserves the right to initiate
                  legal proceedings against You for the fraudulent use of this
                  Platform and/ or the Platform or for any other unlawful act or
                  omission in breach of these Terms;
                </li>
                <li>
                  That images and pictorial representations on the Platform may
                  be enhanced for advertising purposes;
                </li>
                <li>
                  That You shall be responsible for checking the Content,
                  product description and other related information; and
                </li>
                <li>
                  Your contract is with sellers/ merchants on the Platform and
                  you confirm that the product(s) ordered by you are purchased
                  for your internal/personal purpose and not for resale or
                  business purposes. You authorize Heeraya Play to declare and
                  provide a declaration to any governmental authority on your
                  behalf stating the aforesaid purpose of the products ordered
                  by you on the website.
                </li>
                <li>13.2. PROHIBITED USES :</li>
                <li>
                  Heeraya Play has built systems wherein we restrict merchants
                  from offering to sell any prohibited items on the Platform.
                  However, You understand and acknowledge that as a buyer it is
                  also Your responsibility to ensure that at no point You
                  purchase the following products on the Platform in the
                  unlikely event that they are listed by the merchant:
                </li>
                <li>
                  Adult products and pornographic materials (including child
                  pornography) in any form (print, audio/video, multimedia
                  messages, images, photographs, etc.);
                </li>
                <li className="nestedListItem">a. Alcohol;</li>
                <li className="nestedListItem">
                  b. Animals and wildlife products – examples include live
                  animals, mounted specimens, and ivory;
                </li>
                <li className="nestedListItem">
                  c. Artifacts, Counterfeit goods and services infringing the IP
                  (as defined below);
                </li>
                <li className="nestedListItem">d. Crude oil;</li>
                <li className="nestedListItem">
                  e. Electronic surveillance equipment prohibited by law;{" "}
                </li>
                <li className="nestedListItem">
                  f. Embargoed goods from prohibited countries;{" "}
                </li>
                <li className="nestedListItem">
                  g. Endangered species of animals and plants, whether alive or
                  dead;
                </li>
                <li className="nestedListItem">
                  h. Event tickets which are exempted from resale by law;
                </li>
                <li className="nestedListItem">
                  i. Firearms, weapons, and knives – examples include pepper
                  spray, replicas, and stun guns;
                </li>
                <li className="nestedListItem">j. Any financial services;</li>
                <li className="nestedListItem">
                  k. Food and healthcare items without holding requisite
                  permits;
                </li>
                <li className="nestedListItem">l. Grey market products;</li>
                <li className="nestedListItem">
                  m. Government-related items/ equipment (like wireless
                  equipment with the frequency used by the police, uniforms of
                  Government officials including but not limited to the use by
                  the police/ the Indian army, etc.)
                </li>
                <li className="nestedListItem">
                  n. Government-issued documents like passports etc.;
                </li>
                <li className="nestedListItem">
                  o. Hazardous, restricted, or regulated materials – examples
                  include batteries, fireworks, and refrigerants;
                </li>
                <li className="nestedListItem">
                  p. Human remains and body parts;
                </li>
                <li className="nestedListItem">
                  q. IP in any form (including but not limited to music, movies,
                  books, designs) for which the merchant does not hold the
                  distribution rights;
                </li>
                <li className="nestedListItem">
                  r. Invoices and receipts (including blank and pre-filled);
                </li>
                <li className="nestedListItem">
                  s. Liquefied petroleum gas cylinder;
                </li>
                <li className="nestedListItem">t. Lottery tickets;</li>
                <li className="nestedListItem">
                  u. Mailing lists and personal information;
                </li>
                <li className="nestedListItem">
                  v. iMaps and literature where Indian external boundaries have
                  been shown incorrectly;{" "}
                </li>
                <li className="nestedListItem">
                  w. Medicines, drugs and drug paraphernalia that require a
                  registered medical practitioner’s prescription;
                </li>
                <li className="nestedListItem">
                  x. Narcotic drugs and psychotropic substances as defined under
                  the Narcotic Drugs and
                </li>
                <li className="nestedListItem">
                  y. Psychotropic Substances Act, 1985;
                </li>
                <li className="nestedListItem">
                  z. Offensive material which is likely to offend the sentiments
                  of people whether on the grounds of religion, race, caste, sex
                  or place of birth, race, ethnicity, or culture;
                </li>
                <li className="nestedListItem">aa. Radioactive materials;</li>
                <li className="nestedListItem">ab. Reptile skins;</li>
                <li className="nestedListItem">
                  ac. Sex determination Kit as under the Pre-Conception and
                  Pre-Natal Diagnostic Techniques Act,
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------14---------------- */}

              <h2>14. Privacy</h2>
              <ol className="listItems">
                <li>
                  Heeraya Play may collect some of Your personal information and
                  data while accessing, availing and/or using the Platform. Such
                  information collected is only attributed to the functionality
                  of the Platform. You understand and accept that all the User
                  information collected by Heeraya Play may be shared and
                  distributed with Heeraya Play’s business associates (including
                  but not limited to partners, advertisers, contractors, etc.)
                  and affiliates. You may read Heeraya Play's Privacy Policy by
                  visiting the following link:{" "}
                  <Link to="/privacy">Privacy policy</Link>
                </li>
                <li>
                  We take the protection of your privacy very seriously, and
                  hence we accord all information related to your account with
                  the high standards of data protection and security measures as
                  mandated under the Information Technology Act 2000 and the
                  rules thereunder. Our current Privacy Policy is available
                  here.
                </li>
                <li>
                  You hereby agree to indemnify, defend and hold Heeraya Play
                  and its affiliates and their officers, directors and
                  employees, harmless from and against any and all damages,
                  liabilities, costs and expenses, including attorney’s fees and
                  expenses, arising out of, incident to, or resulting directly
                  or indirectly from Your use of the Platform. Notwithstanding
                  its reasonable efforts, Heeraya Play takes no responsibility
                  or control for the Content accessed by You through the
                  Platform.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------15---------------- */}

              <h2>15. No Liability</h2>
              <ol className="listItems">
                <li>
                  TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
                  SHALL Heeraya Play OR ITS AFFILIATES BE LIABLE FOR ANY DIRECT,
                  INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL
                  DAMAGES, FOR LOSS OF PROFITS OR CONFIDENTIAL OR OTHER
                  INFORMATION, FOR BUSINESS INTERRUPTION, FOR PERSONAL INJURY,
                  FOR LOSS OF PRIVACY, FOR FAILURE TO MEET ANY DUTY INCLUDING OF
                  GOOD FAITH OR OF REASONABLE CARE, FOR NEGLIGENCE, AND FOR ANY
                  OTHER PECUNIARY OR OTHER LOSS WHATSOEVER ARISING OUT OF OR IN
                  ANY WAY RELATED TO THE USE OR INABILITY TO USE THE PLATFORM
                  AND ANY LOSS OR DAMAGE ARISING OUT OF OR IN RELATION TO ACTS
                  OF GOD OR ACT OF THIRD PARTY THAT IS BEYOND THE CONTROL OF
                  Heeraya Play OR ITS AFFILIATES.
                </li>
                <li>
                  Heeraya Play OR ITS AFFILIATES SHALL NOT BE LIABLE FOR ANY
                  DIRECT OR INDIRECT DAMAGE FOR ANY DATA PROVIDED BY THE USER OR
                  ANY VIOLATION OF THIRD-PARTY RIGHTS OF WHATSOEVER NATURE,
                  ARISING OUT OF OR IN ANY MANNER RELATED TO THE USE OR
                  INABILITY TO USE THE PLATFORM.
                </li>
                <li>
                  In no event shall Heeraya Play's aggregate liability for all
                  claims relating to the platform exceed ₹5000/-(rupees five
                  thousand only). This limitation of liability is part of the
                  basis of the relationship between You and Heeraya Play and
                  shall apply to all claims of liability (e.g. warranty, tort,
                  negligence, contract, law) and even if Heeraya Play or its
                  affiliates have been told of the possibility of any such
                  damage, and even if these remedies fail their essential
                  purpose.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------16---------------- */}

              <h2>16. Severability</h2>
              <ol className="listItems">
                <li>
                  If any provision in this Agreement becomes invalid or illegal
                  or adjudged unenforceable, the provision shall be deemed to
                  have been severed from this Agreement and the remaining
                  provisions of this Agreement shall not, so far as possible, be
                  affected by the severance.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------17---------------- */}

              <h2>17. Waiver</h2>
              <ol className="listItems">
                <li>
                  The failure of Heeraya Play to enforce at any time any of the
                  provisions of this Agreement shall not be construed to be a
                  waiver of its right, power, privilege or remedy or as a waiver
                  of any preceding or succeeding breach on Your part to this
                  Agreement nor shall any single or partial exercise of any
                  right power privilege or remedy preclude any other or further
                  exercise of such or any other right power privilege or remedy
                  provided in this Agreement all of which are several and
                  cumulative and are not exclusive of each other or of any other
                  rights or remedies otherwise available to Heeraya Play at law
                  or in equity.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------18---------------- */}

              <h2>18. Force Majeure And Act of Third Parties</h2>
              <ol className="listItems">
                <li>
                  The performance of any part of this Terms of Service or other
                  Policies by Heeraya Play and its affiliates shall be excused
                  on account of Force Majeure events (including but not limited
                  to act of God, public enemy, epidemic, pandemic, revolt,
                  strikes, riot, terrorist attack, fire, flood, war, typhoon and
                  any regulation of the government or order of any competent
                  statutory or judicial authority or of any government), or any
                  other cause beyond the reasonable control of Heeraya Play, or
                  act of any third party beyond the control of Heeraya Play
                  including but not limited to hacking, data theft, unauthorized
                  access to User account, impersonation, fraud,
                  misrepresentation and so on.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------19---------------- */}

              <h2>19. Modification of This Agreement</h2>
              <ol className="listItems">
                <li>
                  You hereby agree, undertake and confirm that Heeraya Play
                  reserves the right to update, modify or suspend any part of
                  this Agreement and/or Privacy Policy from time to time. Such
                  revised Agreement and/or Privacy Policy shall be effective
                  from the date of such update or modification or suspension. If
                  You disagree with any of the changes to the Agreement, You can
                  refrain from accessing or using the Platform by uninstalling
                  the Platform. Your continued access or use or availing of the
                  Platform following the changes in the Agreement and/ or the
                  Policies (including Privacy Policy, Community guidelines) will
                  indicate Your acceptance and acknowledgment of the changes and
                  You hereby agree, undertake and confirm that You shall be
                  bound by the revised Agreement and/or the Policies.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------20---------------- */}

              <h2>20. Governing Law</h2>
              <ol className="listItems">
                <li className="nestedListItem">
                  20.1. The Agreement shall be governed by the laws for the time
                  being in force in the territory of India and the Courts in
                  Bangalore alone shall have exclusive jurisdiction on the
                  matters concerning this Agreement.
                </li>
                <li className="nestedListItem">
                  20.2. All disputes arising under or in relation to this
                  Agreement may be referred to arbitration before a sole
                  arbitrator. If the Parties fail to agree on the appointment of
                  a sole arbitrator within the time stipulated under the Indian
                  Arbitration and Conciliation Act, 1996 (the “Act”) the Parties
                  shall approach the competent Court under the Act for
                  appointment of the sole arbitrator. The Arbitration
                  proceedings shall be carried out in accordance with the Act
                  and the Rule framed thereunder and the place/seat of
                  Arbitration shall be Terms of Service Privacy Policy Community
                  Guidelines Transparency Report Takedown Policy Help Bangalore.
                  The arbitration proceedings shall be conducted in English.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------21---------------- */}

              <h2>21. Notice</h2>
              <ol className="listItems">
                <li>
                  Notice is specifically given that Heeraya Play is not
                  responsible for the Content or Advertisements accessible
                  through the Platform. Heeraya Play reserves the right in its
                  sole discretion to remove and/or disable access to Content
                  claimed to infringe third-party rights and/or terminate the
                  accounts of the Users of the Platform who may infringe upon
                  the intellectual property or other rights of Heeraya Play
                  and/or other third parties.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------22---------------- */}

              <h2>22. Grievance Redressal Mechanism</h2>
              <ol className="listItems">
                <li>
                  Heeraya Play has implemented the following mechanism for
                  dealing with grievances: Grievances or concerns regarding
                  violations of Terms of Service, Privacy Policy, and Community
                  Guidelines must be addressed to the “Resident Grievance
                  Officer”. The Resident Grievance Officer can be reached by
                  email at &nbsp;
                  <a href="mailto: grievance@heerayaplay.com">
                    grievance@heerayaplay.com
                  </a>
                  &nbsp;or by mail as per detailed in below 26. The complaint
                  must contain such information as is necessary in order for
                  Heeraya Play to dispose-off the complaint.
                </li>
                <li>
                  Heeraya Play has a Grievance Officer to address your concerns
                  regarding data safety, privacy, and other Platform usage
                  concerns. any person/entity who is aggrieved by the content or
                  advertisement published on the Platform may file a complaint
                  against such content or advertisement. The legal heir, agent
                  or attorney of the aggrieved person/entity may also file a
                  complaint against such content or . If the complaint does not
                  fall under the ambit of a crime, then an unrelated
                  person/entity, who has no interest in or is not aggrieved by
                  the content or the advertisement cannot file a valid complaint
                  against the content or the advertisement. If you are an agent
                  or attorney of the aggrieved party, then you are required to
                  submit documentary proof, establishing your right to file a
                  complaint on behalf of the aggrieved party.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------23---------------- */}

              <h2>23. Complaint and Takedown Process</h2>
              <ol className="listItems">
                <li>
                  23.1. If you are filing a complaint against content, you are
                  required to provide the following information :
                </li>
                <li className="nestedListItem">
                  ● Your interest in filing a complaint
                </li>
                <li className="nestedListItem">● Nature of the Complaint</li>
                <li className="nestedListItem">
                  ● Details of the content/advertisement against which the
                  complaint is filed (title/date of publication//link to the
                  content)
                </li>
                <li className="nestedListItem">
                  ● Relation of the aggrieved party with the
                  content/advertisement
                </li>
                <li className="nestedListItem">
                  ● Accurate facts about how the grievance is caused
                </li>
                <li className="nestedListItem">● Remedies you are seeking</li>
                <li className="nestedListItem">
                  ● Details of the complaint (explanation of the grievance)
                </li>
                <li className="nestedListItem">
                  ● Details of the person filing the complaint (you)
                </li>
                <li className="nestedListItem">
                  ● Details of the Aggrieved party
                </li>
                <li className="nestedListItem">● Communication Details</li>
                <li className="nestedListItem">
                  ● Documentary proof, if applicable
                </li>
                <li>
                  All notices to Heeraya Play hereunder shall be in writing and
                  shall be duly given if delivered personally or sent by
                  registered mail, return receipt requested, or facsimile
                  emailed to the following email id: &nbsp;
                  <a href="mailto: grievance@heerayaplay.com">
                    grievance@heerayaplay.com
                  </a>
                </li>
                <li>23.2. Takedown Process</li>
                <li>
                  This Platform operated by Heeraya Play’ Innovation Private
                  Limited (“Heeraya Play”), and all rights related to the
                  Platform vest in Heeraya Play. These Guidelines deal with the
                  reporting, investigation and resolution of any complaint or
                  grievance against the user generated content or any other
                  content displayed or broadcasted on the Platform. These
                  Guidelines also provide instructions regarding the filing of
                  the complaint. These Guidelines are provided to help the
                  person filing the complaint (“you”) understand how to file a
                  complaint against any Content or advertisement displayed on
                  the Platform, how your complaint will be dealt with by Heeraya
                  Play and the legal requirements related to the content and
                  advertisement displayed on the Platform. For the purpose of
                  these Guidelines, any reference to Heeraya Play includes its
                  subsidiaries, parent entities and sister concerns.
                </li>
                <li>
                  You may send an email with all the necessary information to
                  &nbsp;
                  <a href="mailto: grievance@heerayaplay.com">
                    grievance@heerayaplay.com
                  </a>
                  , with the subject <b>“Takedown Request”</b>.
                </li>
                <h3>Grievance Mechanism :</h3>
                <li>
                  For a compliant or any other issue faced by a user may be
                  submitted through email at the below address. The compliant
                  should provide: (i) username of the relevant account holder
                  from our platform (ii) specific content/video number or url or
                  link that is of concern and (iii) reason(s) for such a
                  takedown request
                </li>
                <li>
                  In accordance with Information Technology Act 2000 and
                  Information Technology (Intermediary Guidelines and Digital
                  Media Ethics Code) Rules, 2021 rules made there under, the
                  contact details of the Grievance Mechanism is as under :
                </li>
                <li>
                  <a href="mailto: grievance@heerayaplay.com">
                    grievance@heerayaplay.com
                  </a>
                </li>
                <li>
                  Any and all complaints launched by you, either via complaint
                  form, email or post, are investigated and dealt with in
                  accordance with these Guidelines. Any legal notice or any
                  other legal action initiated against Heeraya Play or against
                  any content displayed/broadcast on the Platform is not subject
                  to any terms of these Guidelines.
                </li>
                <li>
                  For the purpose of these Guidelines, Content ‘’ shall mean any
                  and all s, news, videos, images, user generated content,
                  sponsored content or any other content displayed, broadcast or
                  communicated to public on the Platform or any part thereof.
                </li>
                <li>
                  For the purpose of these Guidelines, ‘advertisement’ shall
                  mean any endorsement, advertisement or promotional material
                  displayed or broadcast on the Platform or any part thereof.
                </li>
                <li>
                  Heeraya Play reserves the right to amend these Guidelines at
                  any time at its own discretion and hence, you are requested to
                  read these Guidelines very carefully each time before
                  filing/sending any complaint against any content or
                  advertisement to Heeraya Play.
                </li>
                <h3>Who can file a complaint?</h3>
                <li>
                  Any person/entity who is aggrieved by the Content or
                  advertisement published on the Platform may file a complaint
                  against such content or advertisement. The legal heir, agent
                  or attorney of the aggrieved person/entity may also file a
                  complaint against such content or . If the complaint does not
                  fall under the ambit of a crime, then an unrelated
                  person/entity, who has no interest in or is not aggrieved by
                  the content or the advertisement cannot file a valid complaint
                  against the content or the advertisement.
                </li>
                <li>
                  If you are an agent or attorney of the aggrieved party, then
                  you are required to submit documentary proof, establishing
                  your right to file a complaint on behalf of the aggrieved
                  party.
                </li>
                <h3>What information needs to be provided in the complaint?</h3>
                <li>
                  If you are filing a complaint against content, you are
                  required to provide the following information:
                </li>
                <li className="nestedListItem">● Nature of the Complaint</li>
                <li className="nestedListItem">
                  ● Details of the content/advertisement against which the
                  complaint is filed (title link to the content)
                </li>
                <li className="nestedListItem">
                  ● Relation of the aggrieved party with the
                  content/advertisement
                </li>
                <li className="nestedListItem">
                  ● Accurate facts about how the grievance is caused
                </li>
                <li className="nestedListItem">● Remedies you are seeking</li>
                <li className="nestedListItem">
                  ● Details of the complaint (explanation of the grievance)
                </li>
                <li className="nestedListItem">
                  ● Details of the person filing the complaint (you)
                </li>
                <li className="nestedListItem">
                  ● Details of the Aggrieved party
                </li>
                <li className="nestedListItem">● Communication Details</li>
                <li className="nestedListItem">
                  ● Documentary proof, if applicable (discussed below)
                </li>
                <li>
                  Irrespective of whether you file a complaint via complaint
                  form or via email, you must ensure that all the information,
                  as stated above, is contained in the complaint. If any of the
                  required information is missing from the complaint, the
                  complaint will be treated as incomplete and Heeraya Play may
                  not consider or take any action based on the complaint.
                </li>
                <h3>
                  What does ‘nature of the complaint’ mean and why it is
                  relevant?
                </h3>
                <li>
                  Information regarding the ‘Nature of the complaint’ helps
                  Heeraya Play categorize the complaint based on the legal
                  provisions governing the subject matter of the complaint.
                  Based on your grievance against any content or advertisement
                  published on the Platform, Heeraya Play has provided various
                  options which might specify nature of the complaint. Following
                  is the brief description of each of the category provided in
                  the form:
                </li>
                <li>
                  <b>Copyright infringement:</b> Any Content/advertisement
                  violates copyright, performer’s rights or publicity rights of
                  the aggrieved party. This includes display of content
                  created/owned by the aggrieved party without its permission.
                </li>
                <li>
                  <b>Trademark infringement:</b> Any Content/advertisement
                  violates aggrieved party’s trademark rights or illegally
                  uses/displays any word, logo or any other representation which
                  is protected under trademark laws.
                </li>
                <li>
                  <b>Invasion of Privacy: </b> Any Content/advertisement
                  contains any information, image, text or any other content
                  which is private or otherwise violates privacy rights of any
                  third party.
                </li>
                <li>
                  <b>Defamation:</b> Any Content/advertisement contains any
                  information which is false and 1) harms the reputation or
                  public image of the aggrieved party or 2) otherwise has an
                  adHeeraya Play effect on how the aggrieved party is perceived
                  by people in general.
                </li>
                <li>
                  <b>False/misleading:</b> Any Content/advertisement is false or
                  incomplete and misleads people into believing something
                  incorrect, or wrongfully changes the perspective of people
                  about an entity, event or thing.
                </li>
                <li>
                  <b>Obscene/scandalous content:</b> Any content/advertisement
                  contains any image, text, video, audio or any other
                  representation is repulsive, offensive to modesty, indecent,
                  lewd or immoral and is likely to deprave or corrupt the mind
                  of the viewer.
                </li>
                <li>
                  <b>Content hurts religious sentiment or incites violence:</b>{" "}
                  Any Content advertisement contains any image, text or any
                  other content hurts religious beliefs or sentiments of
                  aggrieved party or directly incites or induces violent
                  behaviour among people. Content/advertisement containing hate
                  speech, content inciting rebellion against the government or
                  any religion or religious organisation can also be reported
                  under this category.
                </li>
                <li>
                  Apart from all these categories, there is an open category, by
                  the title “Otherwise illegal”, provided in the complaint form.
                  If the issue raised in the complaint does not fit under any of
                  the above mentioned categories then you may select this as the
                  nature of the complaint and can separately specify the issue
                  raised in brief.
                </li>
                <li>
                  You can select only one of the categories. If the grievance
                  falls under more than one of the abovementioned categories
                  then you may raise a separate complaint for each category of
                  the grievance and provide specific description of the facts
                  and grievance, along with all the necessary documents required
                  for proving the grievance. If you raise more than one
                  complaint against a single content/advertisement, you are
                  required to mention the complaint ID of all your previous
                  complaints in all subsequent complaints regarding the same
                  content, in order to enable effective handling of the
                  grievance.
                </li>
                <h3>
                  What documentary evidence is required along with the
                  complaint?
                </h3>
                <li>
                  Depending on the nature of the complaint and grievance,
                  documentary proof may vary. Following are some illustrations
                  of the documentary proof which may be relevant for different
                  nature of the complaint:
                </li>
                <li>
                  <b>Copyright infringement: </b> Proof of rights of the
                  aggrieved party and proof of infringement.
                </li>
                <li>
                  <b>Trademark infringement:</b> Trademark registration
                  certificate, proof of infringement.
                </li>
                <li>
                  <b>Violation of Privacy:</b> Proof that the content or the
                  advertisement in private or otherwise violates privacy rights.
                </li>
                <li>
                  If you are the agent or attorney of the aggrieved party, then
                  you are required to attach a power of attorney or
                  authorisation letter, establishing your right to file a
                  complaint on behalf of the aggrieved party, along with the
                  complaint.
                </li>
                <h3>
                  What happens if the information provided in the complaint is
                  incomplete or untrue?
                </h3>
                <li>
                  Being a law-abiding entity, Heeraya Play considers and
                  investigates each complaint filed against any content or
                  advertisement published on the Platform. Although Heeraya Play
                  also conducts an internal investigation if required, its
                  primary source of information while dealing with any grievance
                  is the information provided in the complaint. Based on the
                  information provided in the complaint, Heeraya Play determines
                  the nature of the complaint, the rights/laws violated by the
                  Content/advertisement in question, the parties involved in the
                  matter and other relevant information required for addressing
                  the issue raised in the compliant.
                </li>
                <h3>How can Heeraya Play help in addressing the grievance?</h3>
                <li>
                  Being a mere technology provider and an intermediary under
                  Information Technology Act, 2000, Heeraya Play is obligated to
                  take down the Content or advertisement from the Platform, if{" "}
                  <br />
                  1) it receives a complaint, along with all the necessary
                  documents, prima facie and clearly establishing that the
                  content or advertisement on the Platform is violating the
                  rights of any third party or <br /> 2) it receives an order
                  directing takedown from the appropriate authority under law.
                </li>
                <li>
                  In accordance with the law, Heeraya Play may also provide you
                  with details of the of the content which might help you
                  communicate with the directly and settle your grievance.
                </li>
                <li>
                  Heeraya Play’s process of investigating and addressing the
                  complaints against any Content or advertisement is in
                  accordance with Information Technology Act, 2000, Information
                  Technology (Intermediary Guidelines) Rules, 2011 and all other
                  laws in force in India. Nothing under any law imposes an
                  obligation on Heeraya Play to investigate the matter beyond
                  what is provided in the complaint and any investigation
                  conducted or action taken by Heeraya Play, is at its sole
                  discretion, and Heeraya Play is not required to communicate
                  the same to you or to any other party.
                </li>
                <h3>
                  Is Heeraya Play responsible for publication of content on its
                  Platform?
                </h3>
                <li>
                  No. Heeraya Play is merely an intermediary, which provides a
                  platform to various third party content providers for
                  publication of their content to end users, including you.
                  Heeraya Play is not at all involved in authoring or publishing
                  any content or advertisement on the Platform. <br /> <br />{" "}
                  According to Section 79 of Information Technology Act, 2000,
                  Heeraya Play is not responsible for any content or any part
                  thereof published on the Platform. Heeraya Play’s liability
                  toward any content published on the Platform is limited to
                  taking down the content from the Platform if <br /> <br /> 1)
                  it receives a complaint, along with all the necessary proof,
                  prima facie and clearly establishing that the content is
                  violating rights of third party or <br /> <br /> 2) it
                  receives an order of takedown from the appropriate authority
                  under law. <br /> <br /> As Heeraya Play is not involved in
                  authoring or publishing any content on the Platform, it is not
                  obligated to pay any damages or costs to any aggrieved party
                  for any Content or part thereof violating their rights. Being
                  an intermediary, Heeraya Play is not subject to any civil suit
                  for violation of any rights by any content or part thereof
                  infringing rights of any third party.
                </li>
                <h3>
                  How does Heeraya Play ensure the legality of the content and
                  advertisements on the Platform?
                </h3>
                <li>
                  As explained above, Heeraya Play is merely an intermediary
                  which provides a platform to various third-parties to display
                  their content. Heeraya Play has agreements with these
                  third-party content providers who upload the Content which is
                  displayed on the Platform. In their agreements with Heeraya
                  Play, these content providers have represented that their
                  content or any part thereof uploaded on the Platform does not
                  violate any law or any third-party rights. More specifically,
                  the content providers have represented the following:
                </li>
                <li>
                  ● They are authorised to upload the Content on the Platform,{" "}
                  <br /> <br /> ● The Content does not violate any third-party
                  rights, including copyright, trademark rights, privacy rights,
                  publicity rights or other legal rights, <br /> <br /> ● The
                  Content is not in manner defamatory, obscene, libellous,
                  vulgar, unethical or otherwise illegal, <br /> <br /> ● The
                  Content does not in any manner promote or incite violence,
                  rebellion against the government, or hatred; or hurt any
                  religious sentiments or endorse the use or distribution of any
                  banned substance, product or services.
                </li>
                <li>
                  Heeraya Play also has its own policies and agreements which
                  govern the use of its Platform. These agreements and policies
                  are binding on users, and have been formulated to ensure that
                  the use of the Platform does not violate any law. By using the
                  Platform, users agree to not upload any content (including
                  text, images, videos, comments, etc) which violate any law or
                  Heeraya Play’s terms of use. <br /> <br /> Being an
                  intermediary, Heeraya Play is not required to monitor the
                  content on its Platform. Heeraya Play is only required to take
                  action against any Content only when it receives an order from
                  the appropriate authority or a complete complaint establishing
                  a prima facie case. However, Heeraya Play reserves the right
                  to take down any content if it determines, at its sole
                  discretion, that such content violates any law or the terms of
                  its platform.
                </li>
                <h3>
                  How does Heeraya Play deal with the complaint filed against
                  any content?
                </h3>
                <li>
                  Heeraya Play has competent legal which deal with all the
                  complaints filed against any Content on the Platform. These
                  teams investigate the matter on both factual and legal grounds
                  based on their internal research and the content and evidence
                  provided by you. Based in the Content of the complaint,
                  evidence provided, internal research, legal provisions and
                  response from the , these teams evaluate the complaint and
                  grievance, and decide which remedies can be provided to you or
                  the aggrieved party.
                </li>
                <h3>
                  Does Heeraya Play provide the details of the steps undertaken
                  by it while dealing with the complaint?
                </h3>
                <li>
                  The complaint goes through a complicated scrutinization
                  process, which is strictly confidential in nature. Unless
                  required by law or appropriate judicial/quasi-judicial
                  authority, Heeraya Play may not disclose the steps undertaken
                  by Heeraya Play while dealing with a particular complaint.
                </li>
                <h3>
                  Is Heeraya Play obligated to respond to all the complaints
                  filed?
                </h3>
                <li>
                  Heeraya Play considers all the complaints filed against
                  any/all the Content on the Platform based on various facts,
                  including, but not limited to, information provided in the
                  complaint, nature of the complaint, documentary proofs
                  provided, legal validity of the complaint and grievance in the
                  complaint. Based on these factors, Heeraya Play may or may not
                  send a response to you or take any action based on the
                  complaint. Heeraya Play is not obligated to respond to or take
                  action based on every complaint filed against the content on
                  the Platform.
                </li>
                <h3>
                  What happens if you file a false or frivolous complaint?{" "}
                </h3>
                <li>
                  If you provide false information in the complaint, then
                  Heeraya Play will be unable to gather all the necessary
                  information or address your complaint. If you try to mislead
                  Heeraya Play by providing false or misleading information in
                  the complaint, Heeraya Play may take strict legal action
                  against you in order to safeguard its legal rights and
                  integrity of the Platform. Please note that you may be liable
                  for damages (including costs and attorneys’ fees) if you file
                  false and frivolous complaints, as Heeraya Play expends
                  considerable resources in reviewing complaints. <br /> <br />{" "}
                  Terms of Service Privacy Policy Community Guidelines
                  Transparency Report Takedown Policy Help{" "}
                  <b>Are these Guidelines binding on you?</b> <br /> <br />{" "}
                  These guidelines have been created to help you file a
                  complaint with Heeraya Play against any content on the
                  Platform. These Guidelines elaborate on the legal provisions
                  dealing with or relevant to the complaint filed. Nothing
                  mentioned in these Guidelines falls outside the scope of the
                  law and hence, irrespective of whether these Guidelines are
                  binding, you will be bound by the relevant legal provisions.
                </li>
                <h3>
                  What action can Heeraya Play take against content regarding
                  which you have filed a complaint?
                </h3>
                <li>
                  As an intermediary, Heeraya Play cannot edit or alter the
                  content or any part thereof on its own or pursuant to a
                  complaint received by it. However, Heeraya Play is permitted
                  to take down the content entirely, in certain situations.{" "}
                  <br /> <br />
                  Heeraya Play is required to take down the content in the
                  following cases: <br /> <br /> ● Heeraya Play has received an
                  order to this effect from the appropriate judicial or
                  quasi-judicial authority,
                  <br />
                  <br /> ● There is a legal provision requiring Heeraya Play to
                  remove certain content, <br />
                  <br /> ● The of the content in question takes down the content
                  from its own platform and communicates the same to Heeraya
                  Play, either on its own or following the receipt of a
                  complaint. <br />
                  <br /> Heeraya Play may take down the content in the following
                  cases: <br />
                  <br /> ● It receives a complete and properly substantiated
                  complaint with a prima facie case,
                  <br />
                  <br /> ● Heeraya Play, at its sole discretion, determines that
                  the content violates any law or the terms of its platform.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------24---------------- */}

              <h2>24. Termination</h2>
              <ol className="listItems">
                <li>
                  24.1. You may terminate these Terms at any time by deleting
                  your Account and ceasing all use of the Platform. Please note
                  that, deleting the Platform from your computer or mobile
                  device will not delete your Account, and any User Content that
                  you have previously uploaded will remain on the Platform. If
                  you wish to delete your Account, please login to your Account
                  on the Site or click the Account profile icon in the App, and
                  once inside your Account profile, select "Delete Account".
                  Please note that if you delete your Account, all User Content
                  will automatically be deleted. Additionally, if you wish to
                  delete any particular item of User Terms of Service Privacy
                  Policy Community Guidelines Transparency Report Takedown
                  Policy Help Content that you have uploaded to the Platform,
                  you can do so using the User Content deletion functionalities
                  within the Platform; provided, deleting your User Content will
                  not delete your Account or terminate these terms of Agreement.
                  This Terms, and any posted revision, shall remain in full
                  force and effect while You use the Platform and certain
                  provisions may continue to apply even after termination.
                </li>
                <li>
                  24.2. You may terminate this Agreement by uninstalling or
                  deleting the Platform at any time, for any reason. If you no
                  longer want to use our Services again, and would like your
                  account deleted, contact us at grievance.officer@myHeeraya
                  Play.in We will provide you with further assistance and guide
                  you through the process. Once you choose to delete your
                  account, you will not be able to reactivate your account or
                  retrieve any of the content or information you have added.
                </li>
                <li>
                  24.3. We reserve the right to disable or terminate your user
                  account, remove or disable any content you upload or share, at
                  any time, including if you have failed to comply with any of
                  the provision of these Terms, or if activities occur on your
                  account which, in our sole discretion, would or might cause
                  damage to or impair the Services or infringe or violate any
                  third party rights, or violate any applicable laws or
                  regulations. Upon such termination or suspension, you may not
                  access or use the Platform, and you agree that you will not
                  attempt to re-register with or access the Platform through the
                  use of a different member name or otherwise.{" "}
                </li>
                <li>
                  24.4. Effect of Termination. Termination of the Agreement,
                  your account, or your access to or use of the Platform may
                  include removal of access and barring further use of the
                  Platform. Termination of this Agreement or your account also
                  includes disassociation of your username, your password and,
                  all related information, files and user content associated
                  with or inside your account (or any part thereof), including
                  your user content. On termination of this Agreement, all of
                  Your profile Content and other information may be deleted.
                  However, certain details are maintained with us for archival
                  and legal purposes. Irrespective of your termination, the
                  Content liability shall continue with the User at all times.
                  Upon termination of the Agreement, your right to use the
                  Platform, including mobile software will automatically
                  terminate. Heeraya Play will not have any liability whatsoever
                  to you for any suspension or termination, including for
                  deletion of your user content. Heeraya Play will hold and use
                  the content/data as long as required and/or permissible under
                  local laws. All provisions of the Agreement which by their
                  nature should survive shall survive termination of this
                  Agreement, including without limitation, warranty disclaimers,
                  governing law, and limitations of liability.
                </li>
                <li>
                  24.5 Heeraya Play the right to change these Terms from time to
                  time. If You do not agree to any such changes, You have the
                  discretion to discontinue from accessing, availing or using
                  the Platform. Continued access or use of the Platform
                  following notice of any such changes will indicate Your
                  acknowledgement of such changes and You will be bound by such
                  revised Terms. Terms of Service Privacy Policy Community
                  Guidelines Transparency Report Takedown Policy Help{" "}
                </li>
                <li>
                  24.6. All provisions of this Agreement which by their nature
                  should survive termination shall survive termination,
                  including, without limitation, ownership provisions, warranty
                  disclaimers, indemnity and limitations of liability.
                </li>
              </ol>
            </li>

            <li>
              {/* //! -------------25---------------- */}

              <h2>25. Monthly disclosure of information by Intermediary</h2>
              <ol className="listItems">
                <li>
                  Heeraya Play shares monthly disclosure of information as
                  required under the Information Technology (Intermediary
                  Guidelines and Digital Media Ethics Codes) Rules, 2021. For
                  these disclosures, please visit: Disclosure of Grievance:
                  Grievance Data In accordance with the Code of Ethics, Heeraya
                  Play demonstrates its commitment to comply with such extant
                  rules.
                </li>
              </ol>
            </li>
          </ol>
        </Content>
      </Container>
    </>
  );
}

const Container = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  margin: 10%;
  padding: 10px;
  width: 70%;
  text-align: justify;
  font-family: "Poppins", sans-serif;
  color: #000;
  letter-spacing: 1px;

  h3 {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 5px;
    margin: 10% 5%;
  }

  .title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
    color: #000;
  }

  h2 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    color: #000;
    text-transform: uppercase;
  }

  .listItems {
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 30px;
    margin-bottom: 20px;
    color: #000;
  }
  li {
    font-size: 1.2rem;

    margin-bottom: 30px;
    color: #000;
  }
  .nestedListItem {
    margin-left: 30px;
    margin-top: 20px;
    color: #000;
    font-size: 1.2rem;
  }
  p {
    margin-top: 10px;
    color: #000;
    font-size: 1.2rem;
  }
`;

export default Terms;
