import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import bgImage from "./assets/play_background.jpg";
import tableImage from "./assets/table.png";
import awardImage from "./assets/heeraya_play_award.png";
import storeButtonImage from "./assets/play_store_button.png";
import {
  FaShareAlt,
  FaVolumeMute,
  FaVolumeUp,
  FaInfoCircle,
  FaRedoAlt,
} from "react-icons/fa";

  //? url={`http://34.120.231.25/${token}`} => for video source uri.

function HorizontalPlayer() {
  const { orientation, token } = useParams();
  const [seeking, setSeeking] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [loop, setLoop] = useState(false);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [newPlayed, setNewPlayed] = useState(0);
  const playerRef = useRef(null);

  useEffect(() => {
    let frameId = null;
    const loop = () => {
      setPlayed(newPlayed);
      frameId = window.requestAnimationFrame(loop);
    };
    frameId = window.requestAnimationFrame(loop);
    return () => window.cancelAnimationFrame(frameId);
  }, [newPlayed]);

  const handleVideoClick = () => {
    setPlaying(!playing);
  };

  const handleLoopClick = () => {
    setLoop(!loop);
  };

  const handleMuteClick = () => {
    setMuted(!muted);
  };

  const handleSeekChange = (e) => {
    setNewPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setNewPlayed(state.played);
    }
  };

  return (
    <Screen>
      <Table />
      <Link to="/">
        <AwardImage />
      </Link>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://play.google.com/store/apps/details?id=com.heeraya.heerayaplay"
      >
        <StoreButton />
      </a>
      <PlayerWrapper>
        <div onClick={handleVideoClick}>
          <ReactPlayer
            ref={playerRef}
            id="player"
            className={orientation}
            url={`https://storage.googleapis.com/heerayaplay_india/${token}`}
            width="100%"
            height="720px"
            playing={playing}
            loop={loop}
            muted={muted}
            progressInterval={100}
            onProgress={handleProgress}
            style={{ borderRadius: "20px" }}
          />
        </div>
        <input
          className="slider"
          type="range"
          min={0}
          max={1}
          step="any"
          value={played}
          onMouseDown={handleSeekMouseDown}
          onMouseUp={handleSeekMouseUp}
          onChange={handleSeekChange}
        />
        <PlayerControls>
          <FaShareAlt className="icon" size={25} color="#fff" />
          {muted ? (
            <FaVolumeMute
              className="icon"
              size={25}
              onClick={handleMuteClick}
              color="#fff"
            />
          ) : (
            <FaVolumeUp
              className="icon"
              size={25}
              onClick={handleMuteClick}
              color="#fff"
            />
          )}
          <FaRedoAlt
            className="icon"
            size={25}
            onClick={handleLoopClick}
            color={loop ? "#0f0" : "#fff"}
          />
        </PlayerControls>
      </PlayerWrapper>
    </Screen>
  );
}
const Screen = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url(${bgImage}) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
`;

const Table = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: url(${tableImage}) no-repeat center bottom;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

const AwardImage = styled.div`
  position: absolute;
  bottom: 20px;
  left: 100px;
  width: 200px;
  height: 200px;
  background: url(${awardImage}) no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-10px);
    filter: brightness(1.1);
  }

  @media screen and (max-width: 1400px) {
    width: 150px;
    height: 150px;
  }
  @media screen and (max-width: 1000px) {
    left: 20px;
  }
  @media screen and (max-width: 832px) {
    top: 30px;
    left: 20px;
    width: 100px;
    height: 100px;
    z-index: 100;
  }
`;

const StoreButton = styled.div`
  position: absolute;
  bottom: 30px;
  right: 100px;
  width: 200px;
  height: 200px;
  background: url(${storeButtonImage}) no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-10px);
    filter: brightness(1.1);
  }
  @media screen and (max-width: 1400px) {
    width: 150px;
    height: 150px;
  }
  @media screen and (max-width: 1000px) {
    right: 20px;
  }

  @media screen and (max-width: 832px) {
    top: 30px;
    right: 20px;
    width: 100px;
    height: 100px;
    z-index: 100;
  }
`;

const PlayerWrapper = styled.div`
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
  .slider {
    width: 50%;
    bottom: 30px;
    position: absolute;

    @media screen and (max-width: 832px) {
      width: 90%;
    }
  }

`;

const PlayerControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  position: absolute;
  bottom: 12%;
  padding: 20px;

  .icon {
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background-color: #004f98;
  }
  .icon:hover {
    box-shadow: 0 0 10px #004f98;
  }

  @media screen and (max-width: 832px) {
    width: 80vw;
  }
`;

export default HorizontalPlayer;
