import React from "react";
import { styled } from "styled-components";
import Navbar from "./components/Navbar";
import logo from "./assets/black_logo.png";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <>
      <Navbar logo={logo} menuColor="#000" />
      <Helmet>
        <title>Heeraya Play - Privacy Policy</title>
      </Helmet>
      <Container>
        <Content>
          <h1 className="title">HEERAYA PLAY – PRIVACY POLICY</h1>
          <p>
            Please read this Privacy Policy very carefully. This Privacy Policy
            is made effective immediately by Heeraya Play, having its office at
            17089 17th Floor Block K, Avenue GC 14, Greater Noida West, Gautam
            Buddha Nagar, Uttar Pradesh, 201009.(
            <b>“HeerayaPlay”</b>, <b>“Heeraya”</b>,<b>“We”</b>, <b>“Us”</b>,{" "}
            <b>“Our”</b>)
          </p>
          <ol>
            <li>
              {/* //! -------------1---------------- */}

              <h2>GENERAL</h2>
              <ol className="listItems">
                <li>
                  a. Heeraya takes your (“<b>You</b>”, “<b>Your</b>” or, “
                  <b>User</b>”) privacy very seriously and exercises its best
                  efforts to protect your private data/information. Heeraya
                  endeavours to provide a secure and reliable environment for
                  using its Platform and Services. Any private information
                  collected by Heeraya from you will be used only in accordance
                  with this Privacy Policy and the Terms and Conditions provided
                  hereunder.
                </li>

                <li>
                  b. This Privacy Policy applies to Heeraya’s mobile or desktop
                  application software technology called HeerayaPlay (“
                  <b>Platform</b>” or “<b>Heeraya</b>”) and services offered by
                  Heeraya in connection with the Platform (“<b>Services</b>
                  ”).
                </li>

                <li>
                  c. This Privacy Policy sets out the manner in which We
                  collect, use, maintain and disclose information collected from
                  the Users of mobile or desktop applications and/or Services
                  connected with it.
                </li>

                <li>
                  d. You are advised to read the Policy carefully and provide
                  your consent only if you agree with the terms of the Policy.
                  By using or by registering Your profile with the Platform
                  and/or by using Services offered in connection with the
                  Platform, You are consenting to the use, collection, transfer,
                  storage, disclosure and other uses of Your information in the
                  manner set out in this Privacy Policy. For the purpose of this
                  Policy, any reference to Heeraya shall include its affiliates,
                  subsidiaries and sister concerns.
                </li>

                <li>
                  e. By updating, downloading, installing and/or continuing to
                  access/use this Platform, You agree to be bound by any
                  alteration, amendment, addition or modification to this
                  Privacy Policy.
                </li>

                <li>
                  f. This Privacy Policy does not apply to the practices of
                  third parties that Heeraya does not own, control, or manage
                  including but not limited to any third-party websites,
                  services, applications, or businesses (Third-Party Services).
                  Heeraya does not take responsibility for the content or
                  privacy policies of those Third-Party Services. Heeraya
                  encourages You to carefully review the privacy policies of all
                  Third-Party Services You access through the Platform.
                </li>

                <li>
                  g. This Privacy Policy is subject to the Platform’s Terms of
                  Service
                </li>

                <li>
                  h. The platform and/or services are intended for the users
                  above 13 years of age. In case you do not satisfy the
                  criteria, you are requested not to download, install and/or
                  use the platform and/or services.
                </li>

                <li>
                  i. The content created by You is expected to adhere to all the
                  applicable laws, such but not limited to copyright, and other
                  Intellectual Property Rights. In case any artistic work is
                  used such as music, songs, etc. it is expected of you to
                  provide adequate credit to the original creator. Heeraya shall
                  bear no responsibility in case you content is reported for
                  copyright infringement Heeraya owns no rights to such artistic
                  work and it is the sole responsibility of You to provide
                  adequate credit in Your inputs.
                </li>

                <li>
                  j. The video uploaded on HeerayaPlay may contain the watermark
                  of HeerayaPlay. However, this shall not be construed that in
                  manner, Heeraya owns or otherwise is responsible for its
                  production. Heeraya shall have no responsibility in case of
                  any infringement of any law or rule in force which may have
                  occurred due to the video on the grounds of it bearing the
                  watermark of Heeraya.
                </li>

                <li>
                  k. If You do not agree to any of the provisions of this
                  Privacy Policy, You may not download, install and/or use the
                  Platform and/or Services. Heeraya may revise, alter, add,
                  amend or modify this Privacy Policy at any time by updating
                  this page.
                </li>
              </ol>
            </li>
            <li>
              {/* //! -------------2---------------- */}
              <h2>WHAT INFORMATION MAY BE COLLECTED BY HEERAYA</h2>
              <ol>
                <li>
                  a. We collect and process the information you give us when you
                  create an account and upload content to the Platform. This
                  includes technical and behavioural information about your use
                  of the Platform. We also collect information about you if you
                  download the app and interact with the Platform without
                  creating an account. Heeraya may collect information from
                  Users at various stages from registration on the Platform by
                  the Users to submission of content by the Users or while the
                  User is accessing/using Services.
                </li>

                <li>
                  b. Heeraya may collect personal information that is capable of
                  identifying You. This may include, but is not limited to the
                  information submitted during download, installation and/or
                  use/access of the Platform or in connection with access/use of
                  Services or features offered through the Platform or in
                  connection with the Platform. Personal information collected
                  may include Your name, image, mailing address, email address,
                  phone number and demographic information such as gender,
                  nationality, postcode and other personal information including
                  but not limited to date, time or place of birth, location,
                  interests, preferences, likes and dislikes, and usage
                  information.
                </li>

                <li>
                  c. Heeraya collects personal information only if You submit
                  such information to Us or provide access to such information
                  by connecting to social networking sites through the Platform
                  or Services (for example when You use the facilities or
                  Services connected with the Platform or engage in certain
                  Platform related activities including, but not limited to,
                  becoming a member or signing up for an account or linking an
                  account through a social network including, but not limited to
                  Facebook, Twitter or Google Plus).
                </li>

                <li>
                  d. Heeraya may also collect personal information You may
                  provide when corresponding with Us by email or phone, or to
                  access/use any other interactive/non-interactive features of
                  the Platform and/or Services.
                </li>

                <li>
                  e. You can always refuse to supply personal information;
                  however, it may prevent You from using the Platform and/or
                  certain features of the Platform and Services.
                </li>

                <li>
                  f. Heeraya may collect and store other information, which may
                  or may not be personal information, whenever You use, access
                  or interact with the Platform and/or use its Services. Such
                  information may include the mobile or computer’s name,
                  version, the type of device used, Your operating system and
                  version, the unique ID of the mobile device, third-party apps
                  or websites or services that referred You to this Platform,
                  language preferences, location information, IP address,
                  technical information, information about third-party apps
                  including without limitation package name (which is same as
                  the package ID on play store), package version, installation
                  and/or uninstallation event information and other similar
                  information about the User.
                </li>

                <li>
                  g. Based upon your explicit specific Consent, we may also
                  collect additional information including but not limited to
                  GPS, Phone memory, etc. Such information may be used to update
                  your profile or its Content on the Platform.
                </li>

                <li>
                  h. You may not download, install and/or use the Platform if
                  You do not wish to provide or share personal information
                  and/or other information with Heeraya. By providing personal
                  and other information to Heeraya, You agree to permit Heeraya
                  to use the said information as provided in this Privacy
                  Policy.
                </li>
              </ol>
            </li>

            {/* //! -------------3---------------- */}

            <li>
              <h2>HOW HEERAYA USES THE INFORMATION COLLECTED</h2>
              <ol>
                <li>
                  a. Heeraya may collect and use Your personal information and
                  other information for business and/or non-commercial purposes.
                  Heeraya may use the information for the following purposes:
                  <ol>
                    <li className="nestedListItem">
                      i. Your email address and password may be used to identify
                      You while logging into the Platform. Heeraya may use Your
                      personal information to control Your access to the
                      Platform as well as the use of the Platform and Services,
                      to communicate with You, customize Your experience of
                      using the Platform and/or the content of any email
                      newsletter or other material that Heeraya may send from
                      time to time, and provide information that may be useful
                      or interesting based on the content and personally
                      identifiable information. Your email address may be used
                      to send User information, administrative information,
                      changes in account settings and any changes to the
                      Platform/Services or updates on new policies of Heeraya.
                      Apart from this, if You choose to opt-in to our mailing
                      list, You will receive periodic emails that may relate to
                      company news, related product or service information, etc.
                      Your email address may also be used for responding to any
                      of the inquiries, questions, and/or any otherrequests made
                      by You. If at any time You want to unsubscribe from
                      receiving future emails, Heeraya has included detailed
                      unsubscribe instructions at the bottom of each email, or
                      You may contact Heeraya via the Platform.
                    </li>
                    <li className="nestedListItem">
                      ii. Heeraya may further use the collected information to
                      analyze the use of the Platform and/or Services, and the
                      people visiting and using the Platform, for the purpose of
                      improving the features of the Platform. Heeraya may use
                      Your information to analyse Your use of the Platform for
                      providing You customized content, advertisements and
                      features. Information provided by You helps Heeraya in
                      responding to Your customer service requests and support
                      needs more efficiently.
                    </li>
                    <li className="nestedListItem">
                      iii. Heeraya may use information in the aggregate to
                      understand how Users, as a group, are using the Platform
                      and/or Services. Heeraya may use the personal information
                      provided by the User to prevent or take action against
                      activities that are, or maybe, in breach of Heeraya’s
                      Agreement and any applicable laws.
                    </li>
                    <li className="nestedListItem">
                      iv. Heeraya may use Your non-personal information like
                      information regarding third-party apps, including without
                      limitation, package name (which is the same as the package
                      id on the playstore), package version, installation and/or
                      uninstallation event information for personalizing
                      contents and Services based on Your interests. Heeraya may
                      also use Your non personal information for promoting
                      content with respect to, or from other applications
                      installed or used by You or for creating customized
                      sharing options for You.
                    </li>
                    <li className="nestedListItem">
                      v. Heeraya may use Your information to analyse Your use of
                      the Platform and Services and the people visiting and
                      using the Platform and Services for improving the features
                      of the Platform and Services. While accessing, using or
                      installing the Platform and/or Services, Your Information
                      may also be accessible to third parties whose SDKs
                      (Software Development Kits) are included in the Platform.
                      Such SDKs may include, but not be limited to Google ads,
                      Google Firebase Authentication, Firebase Storage, Firebase
                      Firestore, Google Cloud Platforms (GCP), and Google Cloud
                      CDN. These SDKs may use Your information for generic
                      analytical, and other purposes.
                    </li>
                    <li className="nestedListItem">
                      vi. Heeraya may also use Your Information for market
                      research purposes and may share Your information with its
                      affiliated businesses that it controls (including Heeraya
                      Innovation). Heeraya may also use Your information for
                      targeted advertising, targeted content delivery, and other
                      business purposes unless You specifically opt-out of such
                      uses.
                    </li>
                    <li className="nestedListItem">
                      vii. Heeraya may use Your personally identifiable and/or
                      other information You submit while accessing/using the
                      Platform and Services, providing feedback,
                      making/submitting comments, providing inputs, and/or
                      interacting on the Platform and Services for improving
                      User experience on the Platform and in connection with
                      Services, to provide targeted content, advertising and
                      Services, Terms of Service Privacy Policy Community
                      Guidelines Transparency Report Takedown Policy Help and
                      for other business and/or non-commercial purposes.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            {/* //! -------------4---------------- */}

            <li>
              <h2>LINKING YOUR SOCIAL ACCOUNTS TO HEERAYA</h2>
              <ol>
                <li>
                  a. Our Services allow You to, upon Your direction, link Your
                  Facebook, Instagram, Whatsapp and YouTube account(s) with Your
                  Heeraya account. This allows users to find You more easily on
                  other platforms via Heeraya and redirect them to Your social
                  media account(s).
                </li>
                <li>
                  b. We only receive and store the limited information that
                  Instagram or YouTube permits to be transferred (such as Your
                  name, username or channel name, and public profile) and agreed
                  by You when You first connect Your account from such platforms
                  with Your Heeraya account. This information is stored solely
                  for the purpose of displaying Your linked public profiles on
                  our Platform and is not shared with any third-party apps,
                  websites, or other services.
                </li>
                <li>
                  c. If You no longer want to link Your Instagram or YouTube
                  account with Your Heeraya account, please tap the Instagram or
                  YouTube icon in Your profile page and follow the instructions
                  to remove the Heeraya app access permissions.
                </li>
              </ol>
            </li>

            {/* //! -------------5---------------- */}

            <li>
              <h2>HOW HEERAYA USES THE INFORMATION COLLECTED</h2>
              <ol>
                <li>
                  We take steps to ensure that your information is treated
                  securely and in accordance with this policy. We do not accept
                  any responsibility or liability for these policies. The
                  Internet is not 100% secure. We cannot promise that your use
                  of our websites, apps, or Services will be completely safe.
                  Any transmission of Your information to our Services is at
                  your own risk. We encourage you to use caution when using the
                  Internet. We keep personal information as long as it is
                  necessary or relevant for the practices described in this
                  Privacy Policy, including, for example, for legal compliance,
                  dispute resolution, contract enforcement, backup, archival,
                  and other internal operations purposes. This includes data You
                  or others provide to us and data generated from your use of
                  our Services. We also keep the information as otherwise
                  required by law.
                  <ol>
                    <li className="nestedListItem">
                      a. For the above purpose, Heeraya adopts appropriate data
                      collection, storage, control and processing practices and
                      security measures to protect against unauthorized access,
                      alteration, disclosure, or destruction of Your personal
                      information, username, password, and data stored on the
                      App. Specifically, Heeraya takes care of Your personal
                      information by taking security measures such as password
                      protection, and by monitoring security vulnerabilities at
                      regular intervals and by taking remedial steps to prevent
                      data breach and loss.
                    </li>
                    <li className="nestedListItem">
                      b. Personal information exchange between the
                      Platform/Services and Users is protected by Heeraya using
                      an SSL (Secure Socket Layer) secured communication
                      channel. However, Heeraya cannot guarantee the security of
                      any information obtained through unauthorized entry or
                      use, hardware or software failure, and other factors that
                      may compromise the security of User information at any
                      time. It is important for the User to prevent unauthorized
                      access to his/her account and personal information by
                      limiting access to his/her device.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            {/* //! -------------6---------------- */}

            <li>
              <h2>CONTROL OVER USER INFORMATION</h2>
              <p>
                We want you to be in control of your personal information, in
                accordance with the terms of use:
              </p>
              <ol>
                <li className="nestedListItem">
                  a. <b>Access, Correction, and Portability</b>. You can access
                  and edit your account information right on our Platform.
                  Because your privacy is important to us, we will ask you to
                  verify your identity. We may also reject your request to
                  update your personal information for a number of reasons,
                  including, for example, if the request risks the privacy of
                  other users or is unlawful.
                </li>
                <li className="nestedListItem">
                  b. <b>Revoking permissions</b>. In most cases, if you let Us
                  use your information, you can simply revoke your permission by
                  intimating Us as per the due process for revocation laid down
                  by Us.
                </li>
                <li className="nestedListItem">
                  c. <b>Deletion</b>. While We hope you’ll remain a lifelong
                  Heeraya User, if for some reason you ever want to delete your
                  account, just follow the steps provided here to learn how
                  based on your social login. This includes data You or others
                  provide to us and data generated from your use of our
                  Services. We also keep the information as otherwise required
                  by law. We may also retain certain information in backup for a
                  limited period of time or as required by law.
                </li>
              </ol>
            </li>

            {/* //! -------------7---------------- */}

            <li>
              <h2>SHARING USER INFORMATION</h2>
              <p>
                We work with our corporate group and selected third-party
                partners or business associates who help us provide, operate and
                improve our Services. We also share your information with Cloud
                Service Providers, CDNs, ISPs, Technology Partners, content
                moderation services, measurement providers, advertisers, and
                analytics providers. Also, when and where required by law, we
                will share your information with law enforcement agencies or
                regulators, and with third parties pursuant to a legally binding
                court order. Heeraya reserves the right to transfer the personal
                information of users to another party as a result of business
                partnership, contractual engagement, merger, acquisition, or
                sale of all or a portion of Heeraya’s assets to another party,
                and under such a circumstance, the provisions of this Privacy
                Policy will continue to apply unless You are notified otherwise.
              </p>
              <ol>
                <li className="nestedListItem">
                  a. Heeraya does not sell, trade, or rent Your personal
                  information collected by Heeraya to third parties except as
                  provided in this Policy. Heeraya may share generic aggregated
                  demographic information not linked to any personal
                  information, regarding visitors and users with Heeraya’s
                  business partners, contractors, affiliates and advertisers.
                </li>
                <li className="nestedListItem">
                  b. Heeraya may be required by law or litigation to disclose
                  personal information about the Users. Heeraya may also
                  disclose information about the Users if Heeraya determines
                  that for national security, law enforcement, or other issues
                  of public importance that disclosure of information is
                  necessary.
                </li>
                <li className="nestedListItem">
                  c. Heeraya may disclose the information if it is necessary to
                  enforce the applicable terms of use of the Platform and/or
                  reasonably necessary to protect our rights and property or our
                  officers, directors, shareholders, employees or agents.
                </li>
                <li className="nestedListItem">
                  d. Heeraya reserves the right to transfer personal information
                  of Users to another party as a result of business partnership,
                  contractual engagement, merger, acquisition, or sale of all or
                  a portion of Heeraya’s assets to another party, and under such
                  a circumstance, the provisions of this privacy policy will
                  continue to apply unless You are notified otherwise.
                </li>
              </ol>
            </li>

            {/* //! -------------8---------------- */}

            <li>
              <h2>COOKIES</h2>
              <p>
                We may use cookies, for example, to keep track of your
                preferences and Account profile information, or to engage in
                certain retargeting activities that we feel will better enhance
                your User experience and the efficiency and effectiveness of the
                Platform and certain services and functionalities offered
                thereon. Cookies are also used to collect general usage and
                volume statistical information that does not include Personal
                Information. We may also use another company or third-party
                service to place cookies on your computer to collect
                non-personally identifiable information to compile aggregated
                statistics for us about Users of the Platform.
              </p>
              <p>
                Cookies are small pieces of information that are stored as text
                files by your Internet browser on your computer's hard drive.
                Most Internet browsers are initially set to accept cookies. You
                can set your browser to refuse cookies from websites or to
                remove cookies from your hard drive, but if you do so, you may
                not be able to access or use portions of the Platform, or
                certain offerings on the Platform may not function as intended
                or as well. Also, some browsers have “do not track” features
                that allow you to tell a website not to track you. These
                features are not all uniform. If you block cookies, certain
                features on the Platform may not work. If you block or reject
                cookies, not all of the tracking described herein will stop.
                Please note that certain options you select are browser- and
                device-specific.
              </p>
              <ol>
                <li className="nestedListItem">
                  a. Whenever You access the Platform and/or Services Heeraya
                  may place cookies on Your hard drive for recordkeeping
                  purposes to enhance Your experience or sometimes to
                  personalize Your experience. Cookies are small text files that
                  are placed on Your computer’s hard drive by the Platform You
                  visit. Cookies help Heeraya to identify information relating
                  to Your activities and retain information relating to Your
                  preferences and history on the Platform.
                </li>
                <li className="nestedListItem">
                  b. Heeraya and/or its service providers may use advertising
                  cookies to deliver ads that are more relevant to You and Your
                  interests unless You opt-out of such advertisements.
                </li>
                <li className="nestedListItem">
                  c. You may choose to disable cookies by turning off the cookie
                  feature on the web browser. However, by disabling this
                  feature, some parts of the Platform may not function properly.
                  This may prevent You from taking full advantage of the
                  Platform and Services.
                </li>
              </ol>
            </li>

            {/* //! -------------9---------------- */}

            <li>
              <h2>COMBINING INFORMATION</h2>
              <p>
                Heeraya may combine Your personal and other information across
                the Platform and Services in order to provide offers, promotions
                and information that is most relevant to Your interests, likes
                and/or preferences. Heeraya may also combine information You
                share with Heeraya on the Platform and with respect to Services
                with information You have provided to third parties, who have
                been authorized by You to share the information with Us, for
                providing content, advertising, promotions, and offers, and for
                improving User experience on the Platform and Services. Heeraya
                may share Your information with third parties, who have Your
                information, and who are authorized to combine information.
              </p>
            </li>

            {/* //! -------------10---------------- */}

            <li>
              <h2>HOW LONG HEERAYA RETAIN YOUR DATA</h2>
              <p>
                Heeraya will retain the personal information for the period
                necessary to provide You with the Service. In case, we do not
                need your information in order to provide the service to you, we
                will retain it only for so long as we have a legitimate business
                purpose in keeping such data. On occasions, where we are likely
                to keep this data for longer in accordance with our legal
                obligations or where it is necessary for the establishment,
                exercise or permitted by law.
              </p>
              <p>
                The criteria used to determine our retention periods include:
              </p>
              <ul>
                <li className="nestedListItem">
                  ● The length of time we have an ongoing relationship with You
                  and provide the Services to you (for example, for as long as
                  you keep using the Services)
                </li>
                <li className="nestedListItem">
                  ● Whether there is a legal obligation to which We are subject
                  (for example, certain laws require us to keep records for a
                  certain period of time before we can delete them)
                </li>
                <li className="nestedListItem">
                  ● Whether retention is advisable considering our legal
                  position (such as, for statutes of limitations, litigation or
                  regulatory investigations)
                </li>
              </ul>
              <p>
                Heeraya will keep the personal information of Users for only as
                long as is necessary for the purpose for which it was collected
                and as required by the law. At the end of that retention period,
                Heeraya may either delete the information or anonymize it or
                pseudonymize it.
              </p>
            </li>

            {/* //! -------------11---------------- */}

            <li>
              <h2>HOW YOU CAN STOP HEERAYA FROM USING YOUR DATA</h2>
              <ol>
                <li>
                  a. Heeraya values Your preferences and provides You with
                  options to stop direct marketing communications from Us. You
                  may stop direct marketing communications from Us by doing any
                  of the following:
                  <ol>
                    <li className="nestedListItem">
                      i. Click ‘unsubscribe’ or ‘opt-out from email
                      communications from a particular division, service, or
                      team;
                    </li>
                    <li className="nestedListItem">
                      ii. Login in to Your account and change Your privacy
                      preferences.
                    </li>
                  </ol>
                </li>
                <li>
                  b. If You opt-out of marketing communications, You may still
                  receive non-promotional emails/notifications from Heeraya,
                  such as emails about Your account, updates with respect to the
                  Platform and/or Services, and/or any other business
                  communications.
                </li>
                <li>
                  c. You may uninstall and/or discontinue using the Platform and
                  Services by deleting the Platform from Your device if You
                  disagree with the privacy policy, or do not wish to share Your
                  information for uses provided in this policy.
                </li>
              </ol>
            </li>

            {/* //! -------------12---------------- */}

            <li>
              <h2>CHANGES AND UPDATES TO THIS PRIVACY POLICY</h2>
              <p>
                We may update this Privacy Policy from time to time. When we
                update the Privacy Policy, we will notify you by updating the
                “Last Updated” date at the top of this policy and posting the
                new Privacy Policy and providing any other notice required by
                applicable law. Your continued access to or use of the Platform
                after the date of the updated policy constitutes your acceptance
                of the updated policy. If you do not agree to the updated
                policy, you must stop accessing or using the Platform. Heeraya
                may update and revise this Privacy Policy from time to time. The
                revised Privacy Policy will be posted here.
              </p>
              <p>
                You are encouraged to periodically check this page to stay
                informed about changes to this Privacy Policy. You hereby
                acknowledge and agree that it is your responsibility to review
                this Privacy Policy periodically and become aware of the
                modifications. If You disagree with any of the changes to the
                Privacy Policy, You may refrain from using or accessing the
                Platform and Services. Your continued use of the Platform and/or
                Services following the posting of Terms of Service Privacy
                Policy Community Guidelines Transparency Report Takedown Policy
                Help the revised Policy shall indicate Your acceptance and
                acknowledgment of the changes and You will be bound by the
                changed policy.
              </p>
            </li>
            {/* //! -------------13---------------- */}

            <li>
              <h2>NOTICE AND GRIEVANCE REDRESSAL MECHANISMS</h2>
              <p>
                Notice is specifically given that Heeraya is not responsible for
                the Content or advertisements accessible through the App.
                Heeraya reserves the right in its sole discretion to remove
                and/or disable access to Content claimed to infringe third-party
                rights and/or terminate the accounts of the Users of the
                Platform who may infringe upon the intellectual property or
                other rights of Heeraya and/or other third parties.
              </p>
              <p>
                <b>Grievance Redressal Mechanism</b>
              </p>
              <p>
                Heeraya has implemented the following mechanism for dealing with
                grievances: Grievances or concerns regarding violations of the
                Terms of Service, Agreement, Privacy Policy, and Community
                Guidelines must be addressed to the “Resident Grievance Officer”
                through the process mentioned here.
              </p>
              <ol>
                <li className="nestedListItem">
                  (a) Self-report on the App: You can also report content by
                  using the report content feature within the app on any
                  offending content.
                </li>
              </ol>
              <p>
                The complaint must contain such information as is necessary in
                order for Heeraya to dispose-off the complaint. All notices to
                Heeraya hereunder shall be in writing and shall be duly given if
                delivered personally or sent by registered mail, return receipt
                requested, or facsimile to the above address or emailed. This
                document is published in accordance with the provisions of the
                Information Technology (Intermediary Guidelines and Digital
                Media Ethics Code) Rules, 2021 that require publishing the rules
                and regulations, Privacy Policy and Terms for access or usage of
                the App.
              </p>
              <p>
                I have read and understood the terms of this User Agreement and
                I hereby, out of my free will, unconditionally accept to be
                bound by the same. If You have any grievances or concerns
                regarding this Privacy Policy, You may contact Heeraya’s
                Grievance Officer by email or mail. You may send Your grievances
                to the email address
                <b> (heerayashorts@gmail.com)</b> with the subject “Privacy
                Grievance”. You may also contact the Grievance Officer at the
                above methods via email or writing a letter.
              </p>
            </li>
          </ol>
        </Content>
      </Container>
    </>
  );
}

const Container = styled.div`
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  margin: 10%;
  padding: 10px;
  width: 70%;
  text-align: justify;
  font-family: "Poppins", sans-serif;
  color: #000;
  letter-spacing: 1px;

  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 5px;
    margin: 10% 5%;
  }

  .title {
    text-align: center;
    font-weight: 500;
    margin-bottom: 40px;
    color: #000;
  }

  h2 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    color: #000;
  }

  .listItems {
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 30px;
    margin-bottom: 20px;
    color: #000;
  }
  li {
    font-size: 1.2rem;

    margin-bottom: 30px;
    color: #000;
  }
  .nestedListItem {
    margin-left: 30px;
    margin-top: 20px;
    color: #000;
    font-size: 1.2rem;
  }
  p {
    margin-top: 10px;
    color: #000;
    font-size: 1.2rem;
  }
`;
export default PrivacyPolicy;
