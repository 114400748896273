import React from "react";
import Navbar from "./components/Navbar";
import logo from "./assets/white_logo.png";
import certificate from "./assets/certificate.png";

function Certificate() {
  const userName = "Amar Jyoti";
  const internshipPeriod = "10 August to 10 November";
  const role = "React Native Developer";

  return (
    <>
      <Navbar logo={logo} menuColor="#fff" />
      <div style={styles.certificateContainer}>
        <div style={styles.certificateBorder}>
          <img
            src={certificate}
            alt="Certificate"
            style={styles.certificateImage}
          />
          <div style={styles.textContainer}>
            <h2 style={styles.userName}>{userName}</h2>
            <p style={styles.text}>
              This certifies that <strong>{userName}</strong> has successfully
              completed an internship as a <strong>{role}</strong> using React
              Native CLI, from <strong>{internshipPeriod}</strong>.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

// Styles
const styles = {
  certificateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#eaeaea", // Slightly darker grey background
  },
  certificateBorder: {
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    backgroundColor: "#fff", // White background for the certificate
    maxWidth: "800px",
  },
  certificateImage: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "10px",
  },
  textContainer: {
    backgroundColor: "#f8f8f8", // Light grey background for the text
    margin: "20px",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "inset 0 4px 8px 0 rgba(0,0,0,0.1)",
  },
  userName: {
    fontSize: "1.8em",
    color: "#444",
    marginBottom: "10px",
  },
  text: {
    fontSize: "1.2em",
    color: "#555",
    lineHeight: "1.6",
  },
};

export default Certificate;
