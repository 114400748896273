import React, { useState, useRef, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { links } from "../data";
import { Link } from "react-router-dom";

import { styled } from "styled-components";
const Navbar = ({ logo, menuColor }) => {
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);
  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      linksContainerRef.current.style.height = "0px";
    }
  }, [showLinks]);
  return (
    <Nav menuColor={menuColor}>
      <div className="nav-center">
        <div className="nav-header">
          <Link to='/'>
            <img src={logo} className="logo" alt="logo" />
          </Link>

          <button className="nav-toggle" onClick={toggleLinks}>
            <FaBars />
          </button>
        </div>
        <div className="links-container" ref={linksContainerRef}>
          <ul className="links" ref={linksRef} style={{ color: menuColor }}>
            {links.map((link) => {
              const { id, url, text } = link;
              return (
                <li key={id}>
                  <Link to={url}>{text}</Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Nav>
  );
};

const Nav = styled.div`
  position: absolute;
  width: 100%;
  align-items: center;
  font-family: "Poppins", sans-serif;

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .nav-toggle {
    font-size: 1.5rem;
    color: #d8d9d9;
    mix-blend-mode: difference;
    margin-right: 30px;
    background: transparent;
    border-color: transparent;
    transition: all 0.3s linear;
    cursor: pointer;
  }

  .nav-toggle:hover {
    color: #d8d9d9;
    mix-blend-mode: difference;
    transform: rotate(90deg);
  }

  .logo {
    height: 40px;
    mix-blend-mode: difference;
  }

  .links a {
    color: ${(props) => props.menuColor};
    font-size: 1rem;
    text-transform: capitalize;
    display: block;
    letter-spacing: 2px;
    padding: 0 20px;
    transition: all 0.3s linear;
    text-decoration: none;
    font-family: "Poppins", sans-serif;

    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: ${(props) => props.menuColor};
      transition: width 0.3s ease-in-out;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .links-container {
    color: ${(props) => props.menuColor};
    height: 0;
    overflow: hidden;
    transition: all 0.3s linear;
    mix-blend-mode: difference;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
  }

  .show-container {
    height: 10rem;
  }

  @media screen and (min-width: 800px) {
    .nav-center {
      max-width: 1170px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      font-family: "Poppins", sans-serif;
      font-weight: 100;
    }

    .nav-header {
      padding: 0;
    }

    .nav-toggle {
      display: none;
    }

    .links-container {
      height: auto;
      overflow: visible;
      font-family: "Poppins", sans-serif;
    }

    .links {
      display: flex;
    }

    .links a {
      padding: 0;
      margin: 0 0.5rem;
    }
  }
`;
export default Navbar;
