import React from "react";
import styled from "styled-components";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../assets/white_logo.png";
const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: #212226;
  color: #fff;
`;

const SocialMedia = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 700px) {
    width: 50%;
  }
`;

const Icon = styled.div`
padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
`;

const RightSide = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;

  @media screen and (max-width:700px){
    width: 50%;
    align-items: center;
    justify-content: center;
  }
`;

const Logo = styled.div`
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 20%;
  }
  @media screen and (max-width: 700px) {
    width: 90%;
    img {
      width: 100%;
    }
  }
`;

const DownloadButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px 0px 25px 25px;
  color: #212226;
  font-size: 1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #d8d9d9;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  a {
    color: #212226;
    text-decoration: none;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d8d9d9;
    border-radius: 25px 0px 25px 25px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #212226;
    transition: all 0.3s;
    border-radius: 25px 0px 25px 25px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    background-color: transparent;
    border: 2px solid #d8d9d9;
    &:before {
      width: 100%;
    }
    a {
      text-decoration: none;
      color: white;
    }
  }
  @media (max-width: 1200px) {
    margin-top: 10px;
    border-radius: 20px 0px 20px 20px;
    padding: 10px 20px;
    font-size: 1.3rem;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

const Navigation = styled.ul`
  display: flex;
  gap: 20px;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    
  }
`;

const NavItem = styled.li`
  list-style: none;
  cursor: pointer;
  p {
    color: white;
  }

  a {
    text-decoration: none;
    color: white;

    &:visited {
      color: white;
    }

    &:hover {
      color: #d8d9d9;
    }
  }
`;


function Footer({ logo }) {
  return (
    <FooterContainer>
      <SocialMedia>
        <Icon>
          <FaFacebook size={32} />
          <p>Facebook</p>
        </Icon>

        <Icon>
          <FaInstagram size={32} />
          <p>Instagram</p>
        </Icon>
        <Icon>
          <FaTwitter size={32} />
          <p>Twitter</p>
        </Icon>
        <Icon>
          <FaYoutube size={32} />
          <p>Youtube</p>
        </Icon>
      </SocialMedia>
      <RightSide>
        <Logo>
          <img src={logo} alt="Logo" />
        </Logo>
        <DownloadButton>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.heeraya.heerayaplay"
          >
            Download
          </a>
        </DownloadButton>
        <Navigation>
          <NavItem>
            <Link to="/contact">
              <p>Contact</p>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/terms">
              <p>Terms</p>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/career">
              <p>Career</p>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/privacy">
              <p>Policy</p>
            </Link>
          </NavItem>
          <NavItem>Guidelines</NavItem>
        </Navigation>
      </RightSide>
    </FooterContainer>
  );
}

export default Footer;
