import './App.css';
import Home from './components/Home';
import logo from "./assets/white_logo.png";
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import Career from './components/Career';
import Terms from './Terms';
import Plays from './Plays';
import Certificate from './Certificate';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/career" element={<Career />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="/certificate/hp-ff1c-f011-4b93-a16d-842c22378c8d"
            element={<Certificate />}
          />
          <Route path="/plays/:orientation/:token" element={<Plays />} />
        </Routes>
        <Footer logo={logo} />
      </Router>
    </div>
  );
}

export default App;
