import React from "react";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import logo from "./assets/white_logo.png";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <>
      <Navbar logo={logo} menuColor="#fff" />
      <Helmet>
        <title>Heeraya Play - Contact</title>
      </Helmet>
      <Container>
        <Phone>
          <Title>Contact</Title>
          <Form action="https://formspree.io/f/xayzkzob" method="POST">
            <Input placeholder="Name" name="name" />
            <Input placeholder="Email" type="email" name="email" />
            <Textarea placeholder="Message" name="message" />
            <Button type="submit">Submit</Button>
          </Form>
        </Phone>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #d8d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Phone = styled.div`
  width: 25%;
  border-radius: 40px;
  height: 80%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: -10px 10px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;

  @media screen and (max-width: 1400px) {
    width: 45%;
  }
  @media screen and (max-width: 800px) {
    width: 80%;
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-family: Poppins;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  @media screen and (max-width: 1400px) {
    width: 90%;
  }
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 20px 0;
  font-family: Poppins;
  font-size: 1.2rem;

  @media screen and (max-width: 1400px) {
    font-size: 1rem;
    padding: 10px;
    margin: 10px 0;
  }
`;

const Textarea = styled.textarea`
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  height: 200px;
  margin: 20px 0;
  font-size: 1.2rem;

  font-family: Poppins;

  @media screen and (max-width: 1400px) {
    font-size: 1rem;
    padding: 10px;
    margin: 10px 0;
  }
`;

const Button = styled.button`
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px 0px 25px 25px;
  color: #212226;
  font-size: 1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: #d8d9d9;
  border: none;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #d8d9d9;
    border-radius: 25px 0px 25px 25px;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #212226;
    transition: all 0.3s;
    border-radius: 25px 0px 25px 25px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    background-color: transparent;
    border: 2px solid #d8d9d9;
    &:before {
      width: 100%;
    }
  }
  @media (max-width: 1200px) {
    margin-top: 10px;
    border-radius: 20px 0px 20px 20px;
    padding: 10px 20px;
    font-size: 1.3rem;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export default Contact;
